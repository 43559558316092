import axios from "axios";
import Cookies from "js-cookie";
import {React, useState, useEffect } from "react";


function UseAxios() {
  // const { accessToken} = useStore();
  const usertoken = Cookies.get('user_token')
  
  // console.log(usertoken)
  const instance = axios.create({


    baseURL: 'https://backend.navyaedu.com/',
    // baseURL: 'http://192.168.1.15:9000/',//raman ip  
    headers: {
      Authorization: 'Bearer ' + usertoken,
    },
  });

  return instance;
}

export default UseAxios;
