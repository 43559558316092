import React, { useEffect,useState,useContext } from 'react'
import Logo from '../../assets/images/logo.png'
import whiteLogo from '../../assets/images/logo-white.png'
import playStore from '../../assets/images/play_store.png'
import appleStore from '../../assets/images/apple_store.png'
import { Link } from 'react-router-dom'
import { HiOutlineHome, HiOutlineMail } from 'react-icons/hi'
import { RiFacebookCircleLine } from 'react-icons/ri'
import { FiYoutube } from 'react-icons/fi'
import { BsInstagram } from 'react-icons/bs'
import { FaLinkedin } from 'react-icons/fa'
import { FiTwitter } from 'react-icons/fi'
import { BsTelephone } from 'react-icons/bs'
import { FaXTwitter } from "react-icons/fa6";
import axios from 'axios'
import { Audio } from 'react-loader-spinner'
// import ScrollToTop from "react-scroll-to-top";
import ScrollToTopLink from '../linkScrollTop/ScrollToTopLink'
import { userContext } from '../../Context/UserContextProvider'
import UseAxios from '../../util/UseAxios'
function Footer() {

  const { capVal,mode, setMode } = React.useContext(userContext)
  let [responseData, setResponseData] = React.useState('')
  let [errorData, setErrorData] = React.useState('')
  const[color,setColor]=useState()
  const[backgroundColor,setBackground]=useState("#f6f4f4")
  const instance = UseAxios()

  // const usertoken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDcxODEwYWJhMzc0OGZmZDc4ZjAwN2IiLCJyb2xlIjoidXNlciIsImlhdCI6MTY4NTI1MTUzOSwiZXhwIjoxNjg3ODQzNTM5fQ.5V86CNEz3a_OEzpCMaAF4b5bW-vhigMqUi7a_LIugBQ';

  const usertoken = localStorage.getItem('user_token')

  React.useEffect(() => {
    const fetchData = async () => {

      try {

        const response = await instance.get('/api/v1/app/contact-us')
        console.log(response.data)
        setResponseData(response.data.contact_us)
      } catch (error) {
        setErrorData(error)
      }
    }

    fetchData()
  }, [usertoken])

useEffect(()=>{
  console.log(mode);
if(mode==="dark"){
  setColor('white')
  setBackground('#151515')
}else{
  setColor('#7D7D7D')
  setBackground('#f6f4f4')
}
},[mode])

  return (
    <>
     
{/* <ScrollToTop smooth className='top_to_btn' /> */}
{/* <button className='top_to_btn'>
</button> */}
      <div className="py-10 bg-[`${backgroundColor}`] footer_main_class" style={{background:backgroundColor}}>
        <div className="container">
          <div className="row">
            <div className="col-lg col-md-6 col-sm-6">
              <div>
                <img src={mode=="dark" ? whiteLogo :Logo} alt="" className=" logo" />
              </div>
              <div className="address_div_main footer_heading">
                <p style={{color:color}} >Contact Details</p>
                <div className="address_div">
                  <span>
                    <BsTelephone className="icons" />
                  </span>
                  <p style={{color:color}} >
                    +{responseData?.country_code} {responseData?.phone_no}
                  </p>
                </div>
                <div className="address_div" style={{color:color}}>
                  <span>
                    <HiOutlineMail className="icons" />
                  </span>
                  <p style={{color:color}} >{responseData?.email}</p>
                </div>

                <div className="address_div" style={{color:color}}>
                  <span style={{color:color}}>
                    <HiOutlineHome className="icons" />
                  </span>
                  <p style={{color:color}}>{responseData?.address}</p>
                </div>
                <p className="footer_heading mt-4" style={{color:mode=="dark"&&"green"}} > Follow Us On Social</p>
                <div className="socail_icons_div">
                  <Link to="https://www.facebook.com/navyaedtech">
                  
                    <RiFacebookCircleLine className="icons"  style={{color:color}}/>
                  </Link>
                  <Link to="https://www.instagram.com/navyaedu/">
                   
                    <BsInstagram className="icons" style={{color:color}} />
                  </Link>
                  <Link to="https://www.linkedin.com/company/navyaedu">
                    
                    <FaLinkedin className="icons"  style={{color:color}}/>
                  </Link>
                  <Link to="https://twitter.com/navya_edu">
                   
                    <FaXTwitter className="icons" style={{color:color}}/>
                  </Link>
                  <Link to="https://www.youtube.com/@navya_edu">
                    
                    <FiYoutube className="icons" style={{color:color}} />
                  </Link>
                </div>

                <div className="py-3">
                  <p className="footer_heading mt-4" style={{color:mode=="dark"&&"green"}}>Download Our App Now</p>

                  <div className="2">
                    <div className="py-2">
                      <img src={playStore} alt="" style={{width:'180px'}} />
                    </div>
                    <div className="py-2">
                      <img src={appleStore} alt="" style={{width:'180px'}} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-6">
              <div>
                <p className="footer_heading" style={{color:color}}>
                  General Information and Navigation
                </p>
                <div className="py-2" style={{color:color}}>
                  <ScrollToTopLink to="/" className="block py-2 text-[#7D7D7D]" color={color} mode={mode} >
                    Home
                  </ScrollToTopLink>
                  <ScrollToTopLink to="/about-us" color={color} mode={mode}  >
                    About Us
                  </ScrollToTopLink>
                  <ScrollToTopLink to="/contact-us"  color={color} mode={mode} >
                    Contact Us
                  </ScrollToTopLink>
                  <Link to="#" className="block py-2 text-[#7D7D7D]" style={{color:color}}>
                    Mission and Vision
                  </Link>
                  <Link to="#/" className="block py-2 text-[#7D7D7D]" style={{color:color}}>
                    Our Team
                  </Link>
                  <Link to="/setting?page=faq" className="block py-2 text-[#7D7D7D]" style={{color:color}}>
                    FAQ
                  </Link>

                  <Link to="https://blog.navyaedu.com" className="block py-2 text-[#7D7D7D]" style={{color:color}}>
                    Blog
                  </Link>
                  <Link to="https://blog.navyaedu.com/career/" className="block py-2 text-[#7D7D7D]" style={{color:color}}>
                    Career
                  </Link>

                  <Link to="#news" className="block py-2 text-[#7D7D7D]" style={{color:color}}>
                    News
                  </Link>

                  <Link to="https://blog.navyaedu.com/gallery/" className="block py-2 text-[#7D7D7D]" style={{color:color}}>
                    Gallery
                  </Link>

                  <Link to="#testimonial" className="block py-2 text-[#7D7D7D]" style={{color:color}}>
                    Testimonials
                  </Link>
                  <Link to="https://blog.navyaedu.com/success-story/" className="block py-2 text-[#7D7D7D]" style={{color:color}}>
                    Success Stories
                  </Link>
                  <Link to="https://blog.navyaedu.com/whats-new/" className="block py-2 text-[#7D7D7D]" style={{color:color}}>
                    What's New
                  </Link>
                  <Link to="/contact-us" className="block py-2 text-[#7D7D7D]" style={{color:color}}>
                    Social Media
                  </Link>
                  <Link to="https://www.youtube.com/@navya_edu" className="block py-2 text-[#7D7D7D]" style={{color:color}}>
                    Videos
                  </Link>
                  <Link to="#" className="block py-2 text-[#7D7D7D]" style={{color:color}}>
                    RSS Feed
                  </Link>
                  <Link to="#" className="block py-2 text-[#7D7D7D]" style={{color:color}}>
                    Site Map
                  </Link>

                  <Link
                    to="/refer-and-earn"
                    className="block py-2 text-[#7D7D7D]"
                    style={{color:color}}
                  >
                    Refer & Earn
                  </Link>
                  {/* <Link to="/my-profile" className="block py-2 text-[#7D7D7D]">
                    Profile
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-6">
              <div>
                <p className="footer_heading" style={{color:color}}>
                  User Accounts and Interactions
                </p>
                <div className="py-2">
                  <Link to="/signIn" className="block py-2 text-[#7D7D7D]" style={{color:color}}>
                    Log in
                  </Link>
                  {/* <Link to="/setting" className="block py-2 text-[#7D7D7D]" style={{color:color}}>
                    Account Deactivation
                  </Link> */}
                  <Link to="/my-profile" className="block py-2 text-[#7D7D7D]" style={{color:color}}>
                    Your Profile
                  </Link>
                  <Link
                  style={{color:color}}
                    to="refer-and-earn"
                    className="block py-2 text-[#7D7D7D]"
                  >
                    Invite Friends
                  </Link>
                  <Link 
                  style={{color:color}}
                  to="#" className="block py-2 text-[#7D7D7D]">
                    User Ratings and Reviews
                  </Link>
                  {/* <Link
                    to="/refer-and-earn"
                    className="block py-2 text-[#7D7D7D]"
                  >
                    Refer and Earn
                  </Link> */}
                  {/* <Link to="https://navyaedu.com/contact-us" className="block py-2 text-[#7D7D7D]">
                    Support
                  </Link> */}
                </div>
              </div>

              <div>
                <p className="footer_heading" style={{color:color}}>
                  Policies and Legal Information
                </p>
                <div className="py-2" style={{color:color}}>
                  <Link
                  style={{color:color}}
                    to="/setting?page=privacypolicy"
                    className="block py-2 text-[#7D7D7D]"
                  >
                    Privacy Policy
                  </Link>
                  <Link
                  style={{color:color}}
                    to="/setting?page=termservice"
                    className="block py-2 text-[#7D7D7D]"
                  >
                    Terms and Conditions
                  </Link>
                  <Link 
                  style={{color:color}}
                  to="#" className="block py-2 text-[#7D7D7D]">
                    Copyright Information
                  </Link>
                  <Link
                  style={{color:color}}
                  to="/Refund-policy" className="block py-2 text-[#7D7D7D]">
                    Refund and Cancellation Policy
                  </Link>
                  {/* <Link 
                  style={{color:color}}
                  to="/setting" className="block py-2 text-[#7D7D7D]">
                    Data Minimization
                  </Link> */}
                  <Link
                  style={{color:color}}
                  to="#" className="block py-2 text-[#7D7D7D]">
                    Disclaimer
                  </Link>
                  <Link 
                  style={{color:color}}
                  to="#" className="block py-2 text-[#7D7D7D]">
                    Payment Methods
                  </Link>
                  <Link
                  style={{color:color}}
                  to="#" className="block py-2 text-[#7D7D7D]">
                    Affiliate Programs
                  </Link>
                  <Link 
                  style={{color:color}}
                  to="#" className="block py-2 text-[#7D7D7D]">
                    Accounting and Commerce
                  </Link>
                  {/* <Link to="#" className="block py-2 text-[#7D7D7D]">
                    Buy Now
                  </Link> */}
                  <Link 
                  style={{color:color}}
                  to="/myorder" className="block py-2 text-[#7D7D7D]">
                    Cart
                  </Link>
                  <Link 
                  style={{color:color}}
                  to="/setting?page=appearance" className="block py-2 text-[#7D7D7D]">
                    Appearance (Dark and Light Mode)
                  </Link>
                  <Link 
                  style={{color:color}}
                  to="https://blog.navyaedu.com/donation/" className="block py-2 text-[#7D7D7D]">
                    Donation
                  </Link>
                  <Link 
                  style={{color:color}}
                  to="https://blog.navyaedu.com/partnership/" className="block py-2 text-[#7D7D7D]">
                    Partnerships
                  </Link>
                  <Link 
                  style={{color:color}}
                  to="#" className="block py-2 text-[#7D7D7D]">
                    Rate Us
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-6">
              <div>
                <p className="footer_heading" style={{color:color}}>Courses and Education</p>
                <div className="py-2">
                  <Link style={{color:color}} to="/course" className="block py-2 text-[#7D7D7D]">
                    Course Categories
                  </Link>
                  <Link
                  style={{color:color}}
                  to="#" className="block py-2 text-[#7D7D7D]">
                    Test Reports
                  </Link>
                  {/* <Link to="/course" className="block py-2 text-[#7D7D7D]">
                    Courses
                  </Link> */}
                  <Link style={{color:color}} to="#" className="block py-2 text-[#7D7D7D]">
                    Featured Courses
                  </Link>
                  <Link style={{color:color}} to="#" className="block py-2 text-[#7D7D7D]">
                    Popular Courses
                  </Link>
                  <Link style={{color:color}} to="https://blog.navyaedu.com/fee-structure/" className="block py-2 text-[#7D7D7D]">
                    Fee structure
                  </Link>
                  <Link style={{color:color}} to="#" className="block py-2 text-[#7D7D7D]">
                    K to 12
                  </Link>
                  <Link style={{color:color}} to="#" className="block py-2 text-[#7D7D7D]">
                    UG Entrance & CUET
                  </Link>
                  <Link style={{color:color}} to="#" className="block py-2 text-[#7D7D7D]">
                    MBA Entrance Exam
                  </Link>
                  <Link style={{color:color}} to="#" className="block py-2 text-[#7D7D7D]">
                    NEET PG (NEXT)
                  </Link>
                  <Link style={{color:color}} to="#" className="block py-2 text-[#7D7D7D]">
                    Engineering Recruitment Exams
                  </Link>
                  <Link style={{color:color}} to="#" className="block py-2 text-[#7D7D7D]">
                    Banking Exams
                  </Link>
                  <Link style={{color:color}} to="#" className="block py-2 text-[#7D7D7D]">
                    Insurance Exams
                  </Link>
                  <Link style={{color:color}} to="#" className="block py-2 text-[#7D7D7D]">
                    Campus placements
                  </Link>
                  <Link style={{color:color}} to="#" className="block py-2 text-[#7D7D7D]">
                    Civil Services Exams
                  </Link>
                  <Link style={{color:color}} to="#" className="block py-2 text-[#7D7D7D]">
                    Defense Exams
                  </Link>
                  <Link style={{color:color}} to="#" className="block py-2 text-[#7D7D7D]">
                    Police Exams
                  </Link>
                  <Link style={{color:color}} to="#" className="block py-2 text-[#7D7D7D]">
                    Teaching Exams
                  </Link>
                  <Link style={{color:color}} to="#" className="block py-2 text-[#7D7D7D]">
                    SSC Exams
                  </Link>
                  <Link style={{color:color}} to="#" className="block py-2 text-[#7D7D7D]">
                    Railway Examinations
                  </Link>
                  <Link style={{color:color}}to="#" className="block py-2 text-[#7D7D7D]">
                    Other Government Exams
                  </Link>
                  <Link style={{color:color}} to="#" className="block py-2 text-[#7D7D7D]">
                    State government exams
                  </Link>
                  <Link style={{color:color}} to="https://blog.navyaedu.com/states-uts/" className="block py-2 text-[#7D7D7D]">
                    States/UTs
                  </Link>
                  <Link style={{color:color}} to="https://blog.navyaedu.com/universities/" className="block py-2 text-[#7D7D7D]">
                    Universities
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg col-md-6 col-sm-6">
              <div>
                <p style={{color:color}}className="footer_heading">Resources and Services</p>
                <div className="py-2">
                  <Link style={{color:color}} to="https://blog.navyaedu.com/apply-online/" className="block py-2 text-[#7D7D7D]">
                    Apply Online
                  </Link>
                  <Link style={{color:color}} to="https://blog.navyaedu.com/banks/" className="block py-2 text-[#7D7D7D]">
                    Banks
                  </Link>
                  <Link style={{color:color}} to="https://blog.navyaedu.com/boards/" className="block py-2 text-[#7D7D7D]">
                    Boards
                  </Link>
                  <Link style={{color:color}} to="https://blog.navyaedu.com/books/" className="block py-2 text-[#7D7D7D]">
                    Books
                  </Link>
                  <Link style={{color:color}} to="https://navyaedu.com/" className="block py-2 text-[#7D7D7D]">
                    Test Series
                  </Link>
                  <Link style={{color:color}} to="https://blog.navyaedu.com/answer-keys/" className="block py-2 text-[#7D7D7D]">
                    Answer Keys
                  </Link>

                  <Link style={{color:color}} to="https://blog.navyaedu.com/admit-cards/" className="block py-2 text-[#7D7D7D]">
                    Admit Cards
                  </Link>
                  <Link style={{color:color}} to="#" className="block py-2 text-[#7D7D7D]">
                    Entrance Examinations
                  </Link>

                  <Link style={{color:color}} to="#" className="block py-2 text-[#7D7D7D]">
                    Competitive Examinations
                  </Link>

                  <Link style={{color:color}} to="https://blog.navyaedu.com/navya-skill-test-program-nstp/" className="block py-2 text-[#7D7D7D]">
                    Skill Test
                  </Link>

                  <Link style={{color:color}} to="#" className="block py-2 text-[#7D7D7D]">
                    Certification
                  </Link>
                  <Link style={{color:color}} to="https://blog.navyaedu.com/offers/" className="block py-2 text-[#7D7D7D]">
                    Offers
                  </Link>
                  <Link style={{color:color}} to="https://blog.navyaedu.com/navya-internship-program-nip/" className="block py-2 text-[#7D7D7D]">
                    Internships
                  </Link>
                  <Link style={{color:color}} to="#" className="block py-2 text-[#7D7D7D]">
                    Scholarships
                  </Link>
                  <Link style={{color:color}} to="/events" className="block py-2 text-[#7D7D7D]">
                    Upcoming Events
                  </Link>
                  <Link style={{color:color}} to="https://play.google.com/apps/internaltest/4701747189089380152" className="block py-2 text-[#7D7D7D]">
                    Mobile application
                  </Link>
                </div>
              </div>
            </div>

            <h3 style={{color:color}} className="pt-11 font-bold text-lg text-center text-[#7D7D7D]">
              Copyright ©️ 2023 | All Rights Reserved | iAlpha EdTech Private
              Limited
            </h3>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
