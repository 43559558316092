import React from 'react'
import LoginNavbar from '../LoginNavbar/LoginNavbar'
import Footer from '../Footer/Footer'
import user_profile from '../../assets/images/profile/user-img.png'
import users_icons from '../../assets/images/placeholder-img.png'
import logo from '../../assets/images/logo.png'
import history_star from '../../assets/images/history-star.png'
import points_icon from '../../assets/images/points-icon.png'
import refer_star from '../../assets/images/refer-star.png'
import axios from 'axios'
import ReactSwitch from 'react-switch'
import { BiCopy } from 'react-icons/bi'
import { userContext } from '../../Context/UserContextProvider'
import ReactHtmlParser   from 'html-react-parser';
import UseAxios from '../../util/UseAxios'
const Notification = () => {
  const {mode,notificationreade,setNotificationlenght,fetchnotificationData}=React.useContext(userContext)

  const instance = UseAxios()
  const usertoken = localStorage.getItem("user_token");
  const [spinner, setSpinner] = React.useState(false)

  let [tresponseData, setTresponseData] = React.useState([])
  let [terrorData, setTerrorData] = React.useState('')
  
  
  const fetchData =  () => {
    setSpinner(true)
      instance
      .get('/api/v1/app/notification')
      .then((response)=>{
        console.log(response.data)
      setTresponseData(response.data.notifications);
      setSpinner(false)
      })
      .catch((error)=>{
          setSpinner(false)
          setTerrorData(error);
    })

  };

  React.useEffect(()=>{
  fetchData()
  
  }, [usertoken] );

  let [notificationdetail, setNotificationdetail] = React.useState('')

function sendidthis(flag){
  if(flag === 'close'){
  setNotificationdetail('close')
  console.log(notificationdetail);

  }
  else{

    setNotificationdetail(flag)
  }
}



  return (
    <div>
      <LoginNavbar />
      <div className="w-[93%] m-auto py-3">
        <div className="">
          <h1 className="refer_heading">Notifications</h1>
        </div>
        <div className="user_details_main_top_div">
          <div>
            {/* =========Refer ========== */}


            {
          tresponseData.map((course) => (
<>
            <div 
            
            style={{background:mode=='dark'&& "#151515"}}
            className="refer_single_col notification_single_col flex justify-between" key={course._id}
            onClick={()=>{
              sendidthis(course._id);
              notificationreade(course._id);
              fetchData();
              fetchnotificationData();
              }}>
              <div className="refer_single_col_heading_side">
                <div className="refer_single_col_heading_side_inner">
                  <img src={users_icons} alt="" className="" />
                  <div>
                    
                    <h2 style={{color:mode=='dark'&& "white"}}> 
                      {/* {course.notification_type+' : '+ course.notification_title}   */}
                      {course.notification_type} : {RegExp('&lt;').test(course?.notification_title) ? ReactHtmlParser(`${course?.notification_title.replaceAll('&lt;', '<')}`) :course?.notification_title}
                      
                      </h2>
                      <h3 style={{color:mode=='dark'&& "white"}} >{Date(course.notification_delivery_time) }</h3>        
                  </div>
                </div>
              </div>
                    {course.is_seen === false && <div>
                    <div className=" bg-[#66BE5E] w-3 h-3 rounded-full right-[-5px] top-[-5px]"></div>
                    </div>}
            </div>

            {notificationdetail === course._id && 
                    <div className='overlay_notification'>                      
                      <div 
                      style={{background:mode==="dark"?"#151515":"white "}}
                      className={notificationdetail === 'close'?'none' :'w-[90%] fixed top-1/4 left-14  p-6  notification_Details'}>                        
                      <div className='flex items-start'>
                      <img src={users_icons} alt="" className="w-20" />                        
                      <h3 className='mt-3 mb-3 '>                        
                      {RegExp('&lt;').test(course?.notification_title) ? ReactHtmlParser(`${course?.notification_title.replaceAll('&lt;', '<')}`) :course?.notification_title} 
                        <p
                        style={{color:mode=='dark'&& "white"}}
                         onClick={()=>{sendidthis('close');fetchData();}} className='cursor-pointer text-base absolute top-3 right-4'>X</p>
                        {/* <p className='text-[#7D7D7D] text-sm w-[90%]'>{course.notification_content}</p>                         */}
                      </h3>
                      </div>                        
                      <p className='text-[#383838] text-base' style={{color:mode=='dark'&& "white"}}>
                      {RegExp('&lt;').test(course?.notification_content) ? ReactHtmlParser(`${course?.notification_content.replaceAll('&lt;', '<')}`) :course?.notification_content}
                      </p>
                      {/* <span className='text-sm font-bold'>12/10/2023</span>                       */}
                      </div>                    
                    </div>
                     }

</>
            
            ))
          }



          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  )
}

export default Notification
