import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Header/Navbar'
import { Link } from 'react-router-dom'
import { HiOutlineHome, HiOutlineMail } from 'react-icons/hi'
import { RiFacebookCircleLine } from 'react-icons/ri'
import { BsInstagram } from 'react-icons/bs'
import { FaLinkedin } from 'react-icons/fa'
import { FiTwitter } from 'react-icons/fi'
import { AiFillGoogleCircle } from 'react-icons/ai'
import axios from 'axios'
import { Audio } from 'react-loader-spinner'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactHtmlParser  from 'html-react-parser';
function ScrollToTopOnRouteChange() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}


function TandC() {


  const [spinner, setSpinner] = React.useState(false)
  let [responseData, setResponseData] = React.useState('')
  let [errorData, setErrorData] = React.useState('')
  const [termsAndConditionData, setTermsAndConditionData] = React.useState({
    description: '',
  });

  console.log(termsAndConditionData);
  const usertoken =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDcxODEwYWJhMzc0OGZmZDc4ZjAwN2IiLCJyb2xlIjoidXNlciIsImlhdCI6MTY4NTI1MTUzOSwiZXhwIjoxNjg3ODQzNTM5fQ.5V86CNEz3a_OEzpCMaAF4b5bW-vhigMqUi7a_LIugBQ'

  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true)

      try {
        const instance = axios.create({
          baseURL: 'https://backend.navyaedu.com/',
          headers: {
            Authorization: 'Bearer ' + usertoken,
          },
        })

        const response = await instance.get('/api/v1/app/terms-and-condition')

        setTermsAndConditionData({
          ...response.data.terms_and_condition,
          description: response.data.terms_and_condition.description.replaceAll(
            '&lt;',
            '<'
          ),
        });

        
        // console.log(response.data)
        // setResponseData(response.data.terms_and_condition)


        setSpinner(false)
      } catch (error) {
        setSpinner(false)
        setErrorData(error)
      }
    }

    fetchData()
  }, [usertoken])

  const htmlContent = `
  <style>
  h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
    }
    h3{
      font-size: 1.75rem;
    }
    h2{
      font-size: 2rem;
    }
    p {
      margin-top: 0;
      margin-bottom: 1rem;
  }



      dl, ol, ul {
          margin-top: 0;
          margin-bottom: 1rem;
      }
      ol, ul {
          padding-left: 2rem;
      }

      
    </style>

      ${termsAndConditionData.description}
     `;

     

  return (
    <>
      {spinner && (
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass="loader"
        />
      )}
      <ScrollToTopOnRouteChange />
      <Navbar />
        <div className="w-[90%] m-auto py-10">
          <div className="pt-2">
            <h1 className="refer_heading">Terms And Condition</h1>
          </div>
          <div className="">
            <div className="row ">
              <div className="col-lg-12 col-md-12 col-sm-12 mt-5 contact_socail_icons_div">
                <div className="privacy_policy">

                 {/* <div
                    dangerouslySetInnerHTML={{
                      __html: termsAndConditionData.description,
                    }}
                    ></div> */}

                  <div
                      // dangerouslySetInnerHTML={{
                      //   __html:htmlContent
                      // }}
                      >
                        {ReactHtmlParser(htmlContent)}
                      </div>

      
                </div>
              </div>
            </div>
          </div>
        </div>
      <Footer></Footer>
    </>
  )
}

export default TandC
