import React, {Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import UseAxios from '../../util/UseAxios'
import { ToastContainer, toast } from 'react-toastify'
import { userContext } from '../../Context/UserContextProvider'
import { Audio } from 'react-loader-spinner'
import PinInput from 'react-pin-input'
import CountryList from '../../util/country.json'
import Cookies from 'js-cookie'
import CloseModel from './CloseModel'

function PhoneVerifyModel() {
 
    const [Phone_verify, setPhone_verify] = useState()
    const [showePininput, setShowePininput] = useState(false)
   
    // const {profiledata, setProfiledata,mode}=React.useContext(userContext)
    let mode = Cookies.get("mode")
    const [OTPopen, setOTPOpen] = useState(false);
    const [spinner, setSpinner] = useState(false)
    const [open, setOpen] = useState(false)
    const [phone, setPhone] = React.useState(null)
  let [countryMobile, setCountryMobile] = useState()
  let [Potp, setPotp] = React.useState('')
  const instance = UseAxios()
console.log("phone verify api vala >>>>>>>>>>>>>"+ Phone_verify)
console.log("popup open krne ke leye >>>>>>>>>>"+OTPopen)
  const cancelButtonRef = useRef(null)
  const OTPhandleShow = (e) => {
    return  setOpen(false)
  }

  // Successopen
  const [Successopen, setSuccessOpen] = useState(false)



  const profiledataget = async () => {
    setSpinner(true)

    try {
      const response = await instance.get('/api/v1/auth/profile/')
      console.log(response.data.user)
      // setResponseData(response.data.user)
      setOTPOpen(response.data.user.phone_no_verified  ===   true ? false : true)
    //   setProfiledata(response.data.user)
   
      setSpinner(false)
    } catch (error) {
      setSpinner(false)
    //   setErrorData(error)
    }
  }

  React.useEffect(() => {
    profiledataget()
  }, [])


  function SuccesshandleShow(){
    return (
     
      setSuccessOpen(true),
      setOpen(false),
      setOTPOpen(false)
    
    )
  }

  const handlePOtpChange = (value) => {
    const otpValue = Array.from(value).reduce((acc, currentValue) => {
      return acc + currentValue;
    }, '');
  //   if(otpValue.length<6){
  //     setPhonedisabled(true)
  //   }else{
  //     setPhonedisabled(false)
  // }
    setPotp(otpValue);

  }


    const phone_data={
        country_code : countryMobile,
        phone_no : phone
      }
      
      function updateORGenrate(e){
        e.preventDefault()
        if(!countryMobile && phone === null){
          toast.error("Provide phone number & country code")
          return false
        }
        setSpinner(true)
        instance
        .post('/api/v1/auth/update-phone-no/generate-otp',phone_data )
        .then((response) => {
          console.log(response)
          setSpinner(false)
          setShowePininput(true)
          OTPhandleShow()
          toast.success(response.data.msg)
        })
        .catch((error) => {
          console.log(error)
          // toast.error(error.response)
          setSpinner(false)
        
        })
      }


      
let verify_phoneotp ={
    country_code : countryMobile,
    phone_no : phone,
    otp:parseInt(Potp)
  }
  function VerifyPhoneOtp(e){
    e.preventDefault()
    setSpinner(true)

    instance
    .post('/api/v1/auth/update-phone-no/verify-otp',verify_phoneotp )
    .then((response) => {
      console.log(response)
      setSpinner(false)
      SuccesshandleShow()
    //   profiledataget()
      toast.success(response.data.msg)
    })
    .catch((error) => {
      console.log(error)
      toast.error(error.response.data.msg)
      setSpinner(false)
    
    })
  }




  return (
    <div>
      <CloseModel profiledataget={profiledataget} setSuccessOpen={setSuccessOpen} Successopen={Successopen}/>

<ToastContainer />


<Transition.Root show={OTPopen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOTPOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">


                {spinner && (
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass="loader !h-full"
        />
      )}
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 edit_profile_modal">
                    <div>
                      <h4>Phone Number Verification</h4>
                      <p className="edit_otp_number_para">
                        We texted a one-time verification code 
                       {showePininput && <button className="edit_num" onClick={()=>setShowePininput(false)} >
                          edit number?
                        </button>}
                      </p>
                    </div>

                    <div className="Personal_Info_div ">
                      <div>

                     {!showePininput ? 
                     <form className="pt-5" onSubmit={updateORGenrate}>
                          <div className="text-center">
                         
                            <div className="w-full">
                              <label className="block text-[#775EA5]" style={{color:mode=="dark" ?"white":"black"}}>
                            Phone number
                          </label>
                              <div className='flex'>
                       <select
                       required
                        className="rounded p-3 w-[8rem] border border-[#cabde2]"
                        aria-label="Default select example"
                        onChange={(e)=>setCountryMobile(e.target.value)}
                        value={countryMobile}
                      >
                              <option value=""> Country</option>
                      { 
                          CountryList.map((course) => (
                            <option value={course.dial_code}>
                              {course.name + '('+course.dial_code+')'}
                            </option>
                          ))
                        }
                    </select>
                          <input
                          required
                          style={{color:mode=="dark" ?"white":"black", background:mode=="dark"&&"transparent"}}
                            type="text"
                            value={phone}
                            onChange={(e)=>setPhone(e.target.value)}
                            // value={
                            //   '( ' +
                            //   profiledata?.country_code +
                            //   ' ) ' +
                            //   profiledata?.phone_no
                            // }
                           
                            className="rounded p-3 w-full border border-[#cabde2] ms-1"
                            placeholder="Enter phone number"
                          />
                         
                        </div>
                            </div>

                            <button
                              className="bg-[#66BE5E] py-3 mt-4 mb-3 rounded text-white font-semibold w-[90%] m-auto"
                              onSubmit={updateORGenrate}
                              type='submit'
                            >
                               Send OTP
                            </button>
                           
                            {/* <button className="resend_otp_btn w-[90%] m-auto">
                              Resend OTP
                            </button> */}
                          </div>
                        </form>
                      




:


                        <form className="pt-5" onSubmit={VerifyPhoneOtp}>
                          <div className="text-center">
                         
                            <div className="w-full">
                              <label className="block text-[#775EA5]" style={{color:mode=="dark" ?"white":"black"}}>
                            Phone number
                          </label>
                              <div className='flex'>
                       <input
                        readOnly
                        className="rounded p-3 w-[4rem] border border-[#cabde2]"
                        aria-label="Default select example"
                        // onChange={(e)=>setCountryMobile(e.target.value)}
                        value={countryMobile}
                      >
                              {/* <option value="" selected disabled> {countryMobile}</option> */}
                      {/* { 
                          CountryList.map((course) => (
                            <option value={course.dial_code}>
                              {course.name + '('+course.dial_code+')'}
                            </option>
                          ))
                        } */}
                    </input>
                          <input
                          
                          style={{color:mode=="dark" ?"white":"black", background:mode=="dark"&&"transparent"}}
                            type="text"
                            value={phone}
                            readOnly
                            className="rounded p-3 w-full border border-[#cabde2] ms-1"
                            placeholder="Enter phone number"
                          />
                         
                        </div>

                               <div>
                                <PinInput
                                required
                                  length={6}
                                  initialValue=""
                                  onChange={handlePOtpChange}
                                  type="numeric"
                                  inputMode="number"
                                  style={{
                                    padding: "10px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                  inputFocusStyle={{ borderColor: "#D1D1D1" }}
                                  onComplete={handlePOtpChange}
                                  autoSelect={true}
                                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                                  name="otp"
                                  id="otp"
                                  value={Potp.split("")}
                                  className="opt_input"
                                />
                                <p
                                  className="text-end cursor-pointer hover:text-[#66BE5E] font-bold"
                                  onClick={updateORGenrate}
                                >
                                  Resend OTP
                                </p>
                              </div>
                            
                            </div>

                          
                        
                            <button
                              className="bg-[#66BE5E] py-3 mt-4 mb-3 rounded text-white font-semibold w-[90%] m-auto"
                            //   onClick={VerifyPhoneOtp}
                              type='submit'
                            >
                              Verify OTP
                            </button>
                            {/* <button className="resend_otp_btn w-[90%] m-auto">
                              Resend OTP
                            </button> */}
                          </div>
                        </form>}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}

export default PhoneVerifyModel