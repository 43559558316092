import React, { Fragment, useRef, useState } from 'react'

import { Dialog, Transition } from '@headlessui/react'
// import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import LoginNavbar from '../LoginNavbar/LoginNavbar'
import Footer from '../Footer/Footer'
import user_profile from '../../assets/images/profile/user-img.png'
import TrendingNew from '../TrendingCourses/TrendingNew'
import { FiEdit2 } from 'react-icons/fi'
import { TbMoodEdit } from 'react-icons/tb'
import { BsCheckCircle } from 'react-icons/bs'
import PinInput from 'react-pin-input'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import popular_card_img1 from '../../assets/images/courses/1.png'
import popular_card_img2 from '../../assets/images/courses/2.png'
import popular_card_img3 from '../../assets/images/courses/3.png'
import popular_card_img4 from '../../assets/images/courses/4.png'
import popular_card_img5 from '../../assets/images/courses/5.png'
import axios from 'axios'
import { Audio } from 'react-loader-spinner'
import tah_m from '../../assets/images/tag_m.png'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from 'firebase/app';
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  getAuth,
} from 'firebase/auth';
import { userContext } from '../../Context/UserContextProvider'
import { IoCloseCircleOutline } from "react-icons/io5";
import UseAxios from '../../util/UseAxios'
import CountryList from '../../util/country.json'
import PhoneVerifyModel from '../modal/PhoneVerifyModel'
// Add this code outside your component (e.g., at the top of the file)
const firebaseConfig = {
  apiKey: "AIzaSyAVi1PEi-NYtkhfFz-gc2SG6cMHP5gZHdw",
  authDomain: "navya-tlm.firebaseapp.com",
  projectId: "navya-tlm",
  storageBucket: "navya-tlm.appspot.com",
  messagingSenderId: "866685260976",
  appId: "1:866685260976:web:d16ec1cdd436a2927ed775",
  measurementId: "G-88MHETWSTQ"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}
const Myprofile = () => {

  const today = new Date().toISOString().split('T')[0];
  const {profiledata, setProfiledata,mode}=React.useContext(userContext)
// name gender and birthday input change 
  let [name, setName] = React.useState(profiledata?.name)
  let [dob, setDob] = React.useState('')
  let [gender, setGender] = React.useState('')
  let [phone, setPhone] = React.useState(profiledata?.phone_no)
  let [countryMobile, setCountryMobile] = React.useState()
  let [Potp, setPotp] = React.useState('')
  // edit profile form modal
  const [open, setOpen] = useState(false)
  const handleShow = (e) => {
    setName(profiledata.name)
    setDob(profiledata?.birthday)
    setGender(profiledata?.gender)
    
    setOpen(true)
  }

console.log(phone)
  const handlePOtpChange = (value) => {
    const otpValue = Array.from(value).reduce((acc, currentValue) => {
      return acc + currentValue;
    }, '');
  //   if(otpValue.length<6){
  //     setPhonedisabled(true)
  //   }else{
  //     setPhonedisabled(false)
  // }
    setPotp(otpValue);

  }

  // edit profile form modal
  const [OTPopen, setOTPOpen] = useState(false)
  const OTPhandleShow = (e) => {
    return setOTPOpen(true), setOpen(false)
  }

  // Successopen
  const [Successopen, setSuccessOpen] = useState(false)
  function SuccesshandleShow(){
    return (
     
      setSuccessOpen(true),
      setOpen(false),
      setOTPOpen(false)
    )
  }

  const SuccesshandleClose = (e) => {
    return e.preventDefault(), setSuccessOpen(false), setOTPOpen(false)
  }

  const cancelButtonRef = useRef(null)

  const usertoken = localStorage.getItem('user_token')
  // console.log(usertoken)

  const [spinner, setSpinner] = React.useState(false)
  const [resendSpiner, setresendSpiner] = React.useState(false)
  let [responseData, setResponseData] = React.useState('')
  let [errorData, setErrorData] = React.useState('')
  let [Ername, setErName] = React.useState('')

  

  const instance = UseAxios()

  const [Image, setImage ] = useState('')
  const [fileimg,setFileimg] = useState()
console.log(fileimg)


  const profiledataget = async () => {
    setSpinner(true)

    try {
      const response = await instance.get('/api/v1/auth/profile/')
      console.log(response.data.user)
      // setResponseData(response.data.user)
      // setErName(response.data.user.name)
      setProfiledata(response.data.user)
      
      setSpinner(false)
    } catch (error) {
      setSpinner(false)
      setErrorData(error)
    }
  }

  React.useEffect(() => {
    profiledataget()
  }, [usertoken])
  let [prfresponseData, setprfResponseData] = React.useState('')
  let [prferrorData, setprfErrorData] = React.useState('')
 
  

  const bodyref_json = {
    name: name,
    birthday: dob,
    gender: gender,
    phone_no: phone,
  }

  const navigate = useNavigate()

 

  function Profileimgchange(){
    // e.preventDefault();
    let imageData = new FormData();
    // const instance = axios.create({
    //   baseURL: 'https://backend.navyaedu.com/',
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //     Authorization: 'Bearer ' + usertoken,
    //   }
      
      
    // })
    imageData.append('profile_image', fileimg );
      instance
        .patch(`/api/v1/auth/profile/update-profile-image`,imageData)
        .then((response) => {
          // toast.success(response.data.msg)
          console.log(response);
          profiledataget()
        })
        .catch((error) => {
          // toast.error(error.response.data.msg)
          console.log(error);
        })
   
  }

  function prfSubmit(e){
    e.preventDefault()
   
    if(!profiledata?.phone_no_verified){
    toast.error( "Please verify your mobile number")
      return false;
    }
    setSpinner(true)
    instance
      .patch('/api/v1/auth/profile/update-profile', bodyref_json)
      .then((response) => {
        setSpinner(false)
        setprfResponseData(response.data)
       
        console.log(response.data)
        toast.success('Profile updated Successfully')
        profiledataget()
        if(fileimg != undefined){
          Profileimgchange()

        }
        setImage('')
        setOpen(false)

      })
      .catch((error) => {
        console.log(error)

        setSpinner(false)

        if (error) {
          setprfErrorData(error.response)
          toast.error(error.response.data.msg)
        }
      })

  }


 
  
const heandalchange =(event)=>{
  const imguplode = event.target.files[0];
  setFileimg(imguplode)
  console.log(imguplode)
  setImage(URL.createObjectURL(imguplode))
  console.log(URL.createObjectURL(imguplode))

  
}


function changeprovidefild(e,flag){
  
  if(flag === 'name'){
    setName(e.target.value)
    console.log(e.target.value);
    
  }
  if(flag === 'gender'){
    setGender(e.target.value)
    
  }
  if(flag === 'birth'){
    setDob(e.target.value)

  }
  if(flag === 'phone'){
    setPhone(e.target.value)

  }
//   else{
//   setName(profiledata?.name)
//   // setGender(profiledata?.gender)
//   // setDob(profiledata?.)
//   }
}

const phone_data={
  country_code : countryMobile,
  phone_no : phone
}

function updateORGenrate(){
  if(!countryMobile && !phone){
    toast.error("Provide phone number & country code")
    return false
  }
  setresendSpiner(true)
  instance
  .post('/api/v1/auth/update-phone-no/generate-otp',phone_data )
  .then((response) => {
    console.log(response)
    setresendSpiner(false)
    OTPhandleShow()
    toast.success(response.data.msg)
  })
  .catch((error) => {
    console.log(error)
    // toast.error(error.response)
    setresendSpiner(false)
  
  })
}

let verify_phoneotp ={
  country_code : countryMobile,
  phone_no : phone,
  otp:parseInt(Potp)
}
function VerifyPhoneOtp(){
  instance
  .post('/api/v1/auth/update-phone-no/verify-otp',verify_phoneotp )
  .then((response) => {
    console.log(response)
    // setresendSpiner(false)
    SuccesshandleShow()
    profiledataget()
    toast.success(response.data.msg)
  })
  .catch((error) => {
    console.log(error)
    toast.error(error.response.data.msg)
    // setresendSpiner(false)
  
  })
}

const [OTPopen2, setOTPOpen2] = useState(false)
function  openmodelVerifyPhone () {
  setOTPOpen2(!OTPopen2)
}

const openImageInNewTab = (e) => {
  console.log(e.target.src)
  const imageUrl = e.target.src;
  window.open(imageUrl, '_blank');
};


  return (
    <>
      {spinner && (
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass="loader"
        />
      )}
  {OTPopen2 && <PhoneVerifyModel/>}
      <ToastContainer />

      <LoginNavbar />
      <section className="profile_section">
        <div className="w-[90%] m-auto py-10">
          <div className="pt-2">
            <h1 className="refer_heading">My Profile</h1>
          </div>
          <div className="user_details_main_top_div">
            <div className="rafer_earn_main_box">
              <div className="user_details_main">
                <div>
                  <img
                    //  src={Image != '' ? Image : user_profile}
                    onClick={openImageInNewTab}
                    src={
                      
                      profiledata.profile_image === "" ||
                      profiledata.profile_image === undefined ||
                      profiledata.profile_image === null
                        ? "./plceholdernav/placeholder-img.png"
                        : profiledata?.profile_image
                    }
                    alt="profile"
                    className=" user_img  edit_user_img mx-auto object-cover rounded-full"
                  />
                </div>

                <div className="user_details">
                  <h3>{profiledata?.name}</h3>
                  <p>{profiledata?.email}</p>
                  <p>{profiledata?.phone_no}</p>
                </div>
              </div>
              <div className="earn_points_div ">
                <button className="edit_profile_btn" onClick={handleShow}>
                  <FiEdit2 className="icons" />
                  <span> Edit Profile</span>
                </button>
              </div>
            </div>
          </div>

          <div>
            <div className="Personal_Info_div lg:grid-cols-2 md:grid-cols-1 gap-4">
              <div>
                <div className="">
                  <h1 className="text-left font-bold text-2xl">
                    Personal Info
                  </h1>
                </div>
                <form className="pt-5">
                  <div className="mx-0 md:mx-3 my-2 w-full">
                    <label
                      style={{
                        color: mode == "dark" ? "white" : "black",
                        background: mode == "dark" && "transparent",
                      }}
                      className="block "
                    >
                      Full Name
                    </label>
                    <input
                      style={{
                        color: mode == "dark" ? "white" : "black",
                        background: mode == "dark" && "transparent",
                      }}
                      type="text"
                      value={profiledata?.name}
                      readOnly
                      className=" p-3   w-full"
                      placeholder="Enter full name"
                    />
                  </div>

                  <div className="flex justify-center items-center flex-col md:flex-row">
                    <div className=" my-2 w-full">
                      <label
                        className="block "
                        style={{
                          color: mode == "dark" ? "white" : "black",
                          background: mode == "dark" && "transparent",
                        }}
                      >
                        Email Address
                      </label>
                      <input
                        style={{
                          color: mode == "dark" ? "white" : "black",
                          background: mode == "dark" && "transparent",
                        }}
                        type="email"
                        value={profiledata?.email}
                        readOnly
                        className=" p-3   w-full"
                        placeholder="Enter email address"
                      />
                    </div>
                    <div className="w-full ml-3">
                      <label
                        className="block "
                        style={{
                          color: mode == "dark" ? "white" : "black",
                          background: mode == "dark" && "transparent",
                        }}
                      >
                        Phone number
                      </label>
                      <input
                        style={{
                          color: mode == "dark" ? "white" : "black",
                          background: mode == "dark" && "transparent",
                        }}
                        type="text"
                        readOnly
                        value={
                          "( " +
                          profiledata?.country_code +
                          " ) " +
                          profiledata?.phone_no
                        }
                        className=" p-3 w-full  "
                        placeholder="Enter phone number"
                      />
                    </div>
                  </div>
                  <div className="flex justify-center items-center flex-col md:flex-row">
                    <div className=" my-2 w-full">
                      <label
                        style={{
                          color: mode == "dark" ? "white" : "black",
                          background: mode == "dark" && "transparent",
                        }}
                        className="block "
                        for="gender"
                      >
                        Gender
                      </label>
                      <input
                        style={{
                          color: mode == "dark" ? "white" : "black",
                          background: mode == "dark" && "transparent",
                        }}
                        type="text"
                        value={profiledata?.gender}
                        readOnly
                        className=" p-3   w-full"
                        placeholder="Gender"
                      />
                    </div>
                    <div className="w-full ml-3">
                      <label
                        className="block "
                        style={{
                          color: mode == "dark" ? "white" : "black",
                          background: mode == "dark" && "transparent",
                        }}
                      >
                        Date Of Birth
                      </label>
                      <input
                        style={{
                          color: mode == "dark" ? "white" : "black",
                          background: mode == "dark" && "transparent",
                        }}
                        type="text"
                        readOnly
                        value={profiledata?.birthday}
                        className="p-3 w-full"
                        placeholder={profiledata?.birthday}
                      />
                    </div>
                  </div>
                </form>
              </div>

              {/* <div className="pb-0 mt-100 text-center flex social_login_btn_width">
                <p className="  bg-[white] inline rounded">Link your Social Account</p>
                <div
                  className="pb-0 mt-100 text-center flex social_login_btn_width"
                  style={{ justifyContent: 'space-around' }}
                >
                  <button className="order_details_box_shadow py-3 px-3 bg-[#e6e6e6] inline rounded"
                    onClick={(e) => handleLinkSocialAccount(e, 'google')}
                  >
                    <img
                      src={require(`../../assets/images/google.png`)}
                      className="inline "
                      alt=""
                      width={25}
                    />
                  </button>

                  <button className="order_details_box_shadow py-3 px-3 bg-[#e6e6e6] inline rounded"
                    onClick={(e) => handleLinkSocialAccount(e, 'facebook')}
                  >
                    <img
                      src={require(`../../assets/images/facebook.png`)}
                      className="inline "
                      alt=""
                      width={25}
                    />
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      {/* edit profile form modal */}

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  {resendSpiner && (
                    <Audio
                      height="100"
                      width="80"
                      radius="9"
                      color="green"
                      ariaLabel="loading"
                      background="transparent"
                      wrapperClass="loader"
                    />
                  )}
                  <div
                    style={{
                      background: mode === "dark" ? "#151515" : "white",
                      color: mode == "dark" ? "white" : "black",
                    }}
                    className=" px-4 pb-4 pt-3 sm:p-6 sm:pb-4 edit_profile_modal"
                  >
                    <div className="flex justify-end font-extrabold text-2xl cross">
                      <i
                        onClick={() => setOpen(false)}
                        className="btn_hover rounded text-red-600"
                      >
                        <IoCloseCircleOutline />
                      </i>
                    </div>
                    <div>
                      <h4 style={{ color: mode == "dark" ? "white" : "black" }}>
                        Edit Your Profile
                      </h4>
                      <p style={{ color: mode == "dark" ? "white" : "black" }}>
                        Please enter your Details To Edit Your Profile
                      </p>
                    </div>
                    {/* <div className='flex justify-center'> */}

                    <img
                      // src={Image != '' ? Image : user_profile}
                      src={
                        Image === ""
                          ? 
                          profiledata.profile_image === "" ||
                            profiledata.profile_image === undefined ||
                            profiledata.profile_image === null
                            ? "./plceholdernav/placeholder-img.png"
                            : profiledata?.profile_image
                          : Image
                      }
                      alt="profile"
                      width={100}
                      height={100}
                      className=" edit_user_img mx-auto object-cover rounded-full"
                    />

                    <div className="w-full flex justify-center">
                      <input
                        type="file"
                        className="m-auto w-[6.5rem] pt-2 custom-file-input"
                        accept="image/png , image/jpg , image/jpeg"
                        onChange={heandalchange}
                      ></input>
                      {/* <div className='hover:bg-green-600 ' onClick={Apicall} > submit</div> */}
                    </div>

                    {/* </div> */}

                    <div className="Personal_Info_div ">
                      <div>
                        <div className="">
                          <h1
                            style={{
                              color: mode == "dark" ? "white" : "black",
                            }}
                            className="text-left font-bold text-2xl"
                          >
                            Edit Personal Info
                          </h1>
                        </div>
                        <form onSubmit={prfSubmit} className="pt-2">
                          <div className="mx-0 md:mx-0 my-2 w-full">
                            <label
                              style={{
                                color: mode == "dark" ? "white" : "black",
                              }}
                              className="block text-[#775EA5]"
                            >
                              Full Name
                            </label>
                            <input
                              style={{
                                color: mode == "dark" ? "white" : "black",
                                background: mode == "dark" && "transparent",
                              }}
                              type="text"
                              required
                              value={name}
                              onChange={(e) => {
                                changeprovidefild(e, "name");
                              }}
                              className="rounded p-3 border border-[#cabde2] w-full"
                              placeholder="Enter Your Name"
                            />
                          </div>

                          <div className=" justify-center items-center flex-col md:flex-row">
                            <div
                              className=" my-2 w-full"
                              style={{ marginRight: "10px" }}
                            >
                              <label
                                style={{
                                  color: mode == "dark" ? "white" : "black",
                                }}
                                className="block text-[#775EA5]"
                              >
                                Email Address
                              </label>
                              <input
                                style={{
                                  color: mode == "dark" ? "white" : "black",
                                  background: mode == "dark" && "transparent",
                                }}
                                type="email"
                                value={profiledata?.email}
                                readOnly
                                className="rounded p-3 border border-[#cabde2] w-full"
                                placeholder="Enter email address"
                              />
                            </div>
                            <div className="w-full">
                              <label
                                className="block text-[#775EA5]"
                                style={{
                                  color: mode == "dark" ? "white" : "black",
                                }}
                              >
                                Phone number
                              </label>
                              
                             
                              {profiledata.phone_no_verified ?  <input
                                  style={{
                                    color: mode == "dark" ? "white" : "black",
                                    background: mode == "dark" && "transparent",
                                  }}
                                  type="text"
                                  value={phone}
                                  onChange={(e) => {
                                    changeprovidefild(e, "phone");
                                  }}
                                  // value={
                                  //   '( ' +
                                  //   profiledata?.country_code +
                                  //   ' ) ' +
                                  //   profiledata?.phone_no
                                  // }
                                  readOnly={profiledata.phone_no_verified}
                                  className="rounded p-3 w-full border border-[#cabde2] ms-1"
                                  placeholder="Enter phone number"
                                />
                             :
                              <p
                                className="bg-[#66BE5E] h-10 flex justify-center items-center  rounded text-white font-semibold w-[40%]  btn_hover"
                                onClick={openmodelVerifyPhone}
                              >
                                Verify Phone
                              </p>}

                             
                              {/* {!profiledata.phone_no_verified && <p onClick={VerifyPhoneOtp} className='flex justify-end mt-1 cursor-pointer !text-[#66BE5E]'>Verify Phone</p>} */}
                            </div>
                          </div>
                          <div className="flex justify-center items-center flex-col md:flex-row">
                            <div
                              className=" my-2 w-full"
                              style={{ marginRight: "10px" }}
                            >
                              <label
                                style={{
                                  color: mode == "dark" ? "white" : "black",
                                }}
                                className="block text-[#775EA5]"
                                for="gender"
                              >
                                Gender
                              </label>

                              <select
                                style={{
                                  textTransform: "capitalize",
                                  color: mode == "dark" ? "white" : "black",
                                  background: mode == "dark" && "transparent",
                                }}
                                required
                                onChange={(e) => {
                                  changeprovidefild(e, "gender");
                                }}
                                className="rounded p-3  border border-[#cabde2] w-full"
                                name="gender"
                                id="gender"
                                value={gender}
                              >
                                <option
                                  style={{
                                    color: mode == "dark" ? "white" : "black",
                                    background: mode == "dark" && "black",
                                  }}
                                  value=""
                                  // selected={gender}
                                >
                                  Select your gender
                                </option>
                                <option
                                  style={{
                                    color: mode == "dark" ? "white" : "black",
                                    background: mode == "dark" && "black",
                                  }}
                                  value="Male"
                                  // selected={gender}
                                >
                                  Male
                                </option>
                                <option
                                  style={{
                                    color: mode == "dark" ? "white" : "black",
                                    background: mode == "dark" && "black",
                                  }}
                                  value="Female"
                                  // selected={gender}
                                >
                                  Female
                                </option>
                                <option
                                  style={{
                                    color: mode == "dark" ? "white" : "black",
                                    background: mode == "dark" && "black",
                                  }}
                                  value="Others"
                                  // selected={gender}
                                >
                                  Others
                                </option>
                              </select>
                            </div>
                            <div className="w-full">
                              <label
                                style={{
                                  color: mode == "dark" ? "white" : "black",
                                  background: mode == "dark" && "transparent",
                                }}
                                className="block text-[#775EA5]"
                              >
                                Date Of Birth
                              </label>
                              <input
                                style={{
                                  color: mode == "dark" ? "white" : "black",
                                  background: mode == "dark" && "transparent",
                                }}
                                required
                                type="date"
                                min="1970-01-02"
                                max={today}
                                onChange={(e) => {
                                  changeprovidefild(e, "birth");
                                }}
                                // value={profiledata?.birthday}
                                value={dob}
                                className="p-3 w-full"
                                placeholder={profiledata?.birthday}
                              />
                            </div>
                          </div>
                          <div className="text-center  mt-5">
                            <button
                              type="submit"
                              className="bg-[#66BE5E] py-3 rounded text-white font-semibold w-[90%] m-auto btn_hover"
                              // onClick={OTPhandleShow}
                            >
                              Save Changes
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* OTP form modal */}

      <Transition.Root show={OTPopen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOTPOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 edit_profile_modal">
                    <div>
                      <h4>OTP Verification</h4>
                      <p className="edit_otp_number_para">
                        We texted a one-time verification code to 0762780028
                        <button className="edit_num" onClick={handleShow}>
                          edit number?
                        </button>
                      </p>
                    </div>

                    <div className="Personal_Info_div ">
                      <div>
                        <form className="pt-5">
                          <div className="text-center">
                            {/* <PinInput
                              length={4}
                              initialValue=""
                              secret
                              secretDelay={100}
                              type="numeric"
                              inputMode="number"
                              style={{
                                padding: '10px',
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                              inputFocusStyle={{ borderColor: '#D1D1D1' }}
                              onComplete={(value, index) => {}}
                              autoSelect={true}
                              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                              name="otp"
                              id="otp"
                              value=""
                              className="opt_input"
                              autoComplete="off"
                            /> */}
                            <div className="w-full">
                              {/* <label className="block text-[#775EA5]" style={{color:mode=="dark" ?"white":"black"}}>
                            Phone number
                          </label> */}
                              {/* <div className='flex'>
                       <select
                        className="rounded p-3 w-[8rem] border border-[#cabde2]"
                        aria-label="Default select example"
                        onChange={(e)=>setCountryMobile(e.target.value)}
                        value={countryMobile}
                      >
                              <option value=""> Country</option>
                      { 
                          CountryList.map((course) => (
                            <option value={course.dial_code}>
                              {course.name + '('+course.dial_code+')'}
                            </option>
                          ))
                        }
                    </select>
                          <input
                          style={{color:mode=="dark" ?"white":"black", background:mode=="dark"&&"transparent"}}
                            type="text"
                            value={phone}
                            onChange={(e)=>{changeprovidefild(e,'phone')}}
                            // value={
                            //   '( ' +
                            //   profiledata?.country_code +
                            //   ' ) ' +
                            //   profiledata?.phone_no
                            // }
                            readOnly={profiledata.phone_no_verified}
                            className="rounded p-3 w-full border border-[#cabde2] ms-1"
                            placeholder="Enter phone number"
                          />
                         
                        </div> */}

                              <div>
                                <PinInput
                                  length={6}
                                  initialValue=""
                                  // secret
                                  // disabled={phonedisabled}
                                  // secretDelay={100}
                                  onChange={handlePOtpChange}
                                  type="numeric"
                                  inputMode="number"
                                  style={{
                                    padding: "10px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                  inputFocusStyle={{ borderColor: "#D1D1D1" }}
                                  onComplete={handlePOtpChange}
                                  autoSelect={true}
                                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                                  name="otp"
                                  id="otp"
                                  value={Potp.split("")}
                                  className="opt_input"
                                />
                                <p
                                  className="flex justify-end mt-1 cursor-pointer !text-[#66BE5E]"
                                  onClick={updateORGenrate}
                                >
                                  genrate otp
                                </p>
                              </div>
                              <p
                                onClick={VerifyPhoneOtp}
                                className="flex justify-end mt-1 cursor-pointer !text-[#66BE5E]"
                              >
                                Verify Phone
                              </p>
                            </div>

                            <button
                              className="bg-[#66BE5E] py-3 mt-4 mb-3 rounded text-white font-semibold w-[90%] m-auto"
                              onClick={VerifyPhoneOtp}
                            >
                              Verify OTP
                            </button>
                            <button className="resend_otp_btn w-[90%] m-auto">
                              Resend OTP
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Your Changes Have Been
saved Successfully!  */}

      <Transition.Root show={Successopen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setSuccessOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4  ">
                    <div className="success_edit_profile">
                      <BsCheckCircle className="icons" />
                      <h4>Your Changes Have Been saved Successfully! </h4>
                    </div>

                    <div className="Personal_Info_div ">
                      <div>
                        <form className="pt-5">
                          <div className="text-center">
                            <button
                              className="bg-[#66BE5E] py-3 mt-7 rounded text-white font-semibold w-[90%] m-auto"
                              onClick={SuccesshandleClose}
                            >
                              Close
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Footer />
    </>
  );
}

export default Myprofile;
