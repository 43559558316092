import React from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import { userContext } from '../../Context/UserContextProvider';
function ReCaptcha() {
    const {capVal,setCapval }=React.useContext(userContext)
 
    
    function reCaptcha(e){
        setCapval(e)
    }
   

  return (
    <div className='flex justify-center w-full' >

                
                <ReCAPTCHA  
                  // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" // test site key 
                  sitekey="6Ld4_SopAAAAAOVuzaNjSBQOMgABhaobYZqgNnMO" //domain site key 
                  onChange={reCaptcha}
                />
                </div>
  )
}

export default ReCaptcha;