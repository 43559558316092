import React from 'react'
// import Footer from '../Footer/Footer'
import LoginNavbar from '../components/LoginNavbar/LoginNavbar'
// import CourseConfirmationCard from './CourseConfirmationCard'
import { Link } from 'react-router-dom'
import { BsArrowRight } from 'react-icons/bs'
import axios from 'axios'
import { Audio } from 'react-loader-spinner'
import { useCallback } from 'react'
import useRazorpay from 'react-razorpay'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'



function Scholarship() {

    return (
        <div>
            {/* {spinner && (
                <Audio
                    height="80"
                    width="80"
                    radius="9"
                    color="green"
                    ariaLabel="loading"
                    background="transparent"
                    wrapperClass="loader"
                />
            )} */}
            {/* <LoginNavbar></LoginNavbar> */}

            {/* <div className="mycourse_card_main h-[89.5vh]">
                <div className="px-12">



                    <div className="my-2 px-10 pt-2 mt-3 bg-[#b1b1b122] rounded-lg">
                        <div className="flex justify-between items-start  flex-col lg:flex-row md:flex-column">
                            <div className='My_purchased_series_Details d-flex justify-between items-center w-100'>
                                <div className="pt-2">
                                    <div className="flex">
                                        <h1 className="font-bold text-2xl mr-5 series_font">
                                        Stream 1
                                        </h1>

                                    </div>
                                    <div className="flex ">
                                        <p className="series_font">GET 100 Points</p>
                                        <p className="px-2 series_font">
                                            4.8{' '}
                                            <span>
                                                <i className="fa fa-star text-[orange]"></i> (878
                                                ratings)
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="series_font d-flex justify-between">
                                    
                                    <a
                                        href='/Scholarshiptest'
                                    >
                                        <div className="bg-[#66BE5E] text-center text-white font-semibold rounded p-2 my-1 btn_hover">
                                            <button className="series_font">Start</button>
                                        </div>
                                    </a>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="px-12">



                    <div className="my-2 px-10 pt-2 mt-3 bg-[#b1b1b122] rounded-lg">
                        <div className="flex justify-between items-start  flex-col lg:flex-row md:flex-column">
                            <div className='My_purchased_series_Details d-flex justify-between items-center w-100'>
                                <div className="pt-2">
                                    <div className="flex">
                                        <h1 className="font-bold text-2xl mr-5 series_font">
                                        Stream 1
                                        </h1>

                                    </div>
                                    <div className="flex ">
                                        <p className="series_font">GET 100 Points</p>
                                        <p className="px-2 series_font">
                                            4.8{' '}
                                            <span>
                                                <i className="fa fa-star text-[orange]"></i> (878
                                                ratings)
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="series_font d-flex justify-between">
                                    
                                    <a href='/Scholarshiptest'>
                                        <div className="bg-[#66BE5E] text-center text-white font-semibold rounded p-2 my-1 btn_hover">
                                            <button className="series_font">Start</button>
                                        </div>
                                    </a>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="px-12">



                    <div className="my-2 px-10 pt-2 mt-3 bg-[#b1b1b122] rounded-lg">
                        <div className="flex justify-between items-start  flex-col lg:flex-row md:flex-column">
                            <div className='My_purchased_series_Details d-flex justify-between items-center w-100'>
                                <div className="pt-2">
                                    <div className="flex">
                                        <h1 className="font-bold text-2xl mr-5 series_font">
                                        Stream 1
                                        </h1>

                                    </div>
                                    <div className="flex ">
                                        <p className="series_font">GET 100 Points</p>
                                        <p className="px-2 series_font">
                                            4.8{' '}
                                            <span>
                                                <i className="fa fa-star text-[orange]"></i> (878
                                                ratings)
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="series_font d-flex justify-between">
                                    
                                    <a
                                        href='/Scholarshiptest'
                                    >
                                        <div className="bg-[#66BE5E] text-center text-white font-semibold rounded p-2 my-1 btn_hover">
                                            <button className="series_font">Start</button>
                                        </div>
                                    </a>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div> */}

            {/* comimg soon */}

            <div className='scholarship_coming_soon'>
                <div>
                    <img src="./notfound/coming-soon-1.jpg" alt="" />
                    <a href="/">GO HOME</a>
                </div>
            </div>


        </div>
    )
}

export default Scholarship
