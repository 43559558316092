import React from 'react'
import no_events from '../../assets/images/placeholder-img.png'

const NoCourses = (props) => {
  return (
    <>
      <div className="no_courses">
        <div className="row popular_cources_main_section">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="No_Past_Event_card">
              <img src={no_events} alt="" className="img-fluid" />
              <h2>No {props.stat?props.stat:"Courses"} available</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NoCourses
