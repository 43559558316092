import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Header/Navbar'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import ReactHtmlParser  from 'html-react-parser';
import UseAxios from '../../util/UseAxios'
function ScrollToTopOnRouteChange() {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}
const About = () => {

const instance = UseAxios()
  const [spinner, setSpinner] = React.useState(false)
  let [responseData, setResponseData] = React.useState('')
  let [errorData, setErrorData] = React.useState('')
  const [termsAndConditionData, setTermsAndConditionData] = React.useState({
    description: '',
  });

  console.log(termsAndConditionData);
  // const usertoken =
  //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDcxODEwYWJhMzc0OGZmZDc4ZjAwN2IiLCJyb2xlIjoidXNlciIsImlhdCI6MTY4NTI1MTUzOSwiZXhwIjoxNjg3ODQzNTM5fQ.5V86CNEz3a_OEzpCMaAF4b5bW-vhigMqUi7a_LIugBQ'
  const usertoken = localStorage.getItem("user_token");

  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true)

      try {
        const response = await instance.get('/api/v1/app/about-us')
        setTermsAndConditionData({
          ...response.data.about_us,
          description: response.data.about_us.description.replaceAll(
            '&lt;',
            '<'
          ),
        });

        
        // console.log(response.data)
        // setResponseData(response.data.terms_and_condition)


        setSpinner(false)
      } catch (error) {
        setSpinner(false)
        setErrorData(error)
      }
    }

    fetchData()
  }, [usertoken])

  const htmlContent = `
  <style>
  h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
    }
    h3{
      font-size: 1.75rem;
    }
    h2{
      font-size: 2rem;
    }
    p {
      margin-top: 0;
      margin-bottom: 1rem;
  }



      dl, ol, ul {
          margin-top: 0;
          margin-bottom: 1rem;
      }
      ol, ul {
          padding-left: 2rem;
      }

      
    </style>

      ${termsAndConditionData.description}
     `;

console.log(termsAndConditionData.description);
   
  return (
    <>
    
     <Navbar/>
     {/* <ScrollToTopOnRouteChange /> */}
      <div className="w-[90%] m-auto py-10">
        <div className="pt-2">
          <h1 className="refer_heading">About Us</h1>
        </div>
<div className='about_contact_row'>
        <div className='row flex'>
            <div className='col-lg-6 col-md-6 col-sm-12 about_contact_col'>
            <div
                      // dangerouslySetInnerHTML={{
                      //   __html:htmlContent
                      // }}
                      >
                        {ReactHtmlParser(htmlContent)}
                      </div>
         <div className='about_contact_btn'>
            <Link className='' to="/contact-us" >Contact Now</Link>
         </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 imgs'>
            <img src='https://annexlogics.com/static/media/about_us.33bd50b6.svg' className='img-fluid' />
            </div>
        </div>
        </div>
      </div>

{/* <div className="w-[90%] m-auto py-10">
          <div className="pt-2">
            <h1 className="refer_heading">About Us</h1>
          </div>
          <div className="">
            <div className="row ">
              <div className="col-lg-12 col-md-12 col-sm-12 mt-5 contact_socail_icons_div">
                <div className="privacy_policy">

                 

                  <div
                 
                      >
                        {ReactHtmlParser(htmlContent)}
                      </div>

      
                </div>
              </div>
            </div>
          </div>
        </div> */}



  


      <Footer></Footer>
    </>
  )
}

export default About
