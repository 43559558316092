import React, { useEffect, useState } from 'react'
import Navbar from '../Header/Navbar'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import SweetAlert2 from 'react-sweetalert2';
import { userContext } from '../../Context/UserContextProvider';
import { Audio } from 'react-loader-spinner'
import Lottie from 'react-lottie'
import failuregif from '../../assets/images/payments/Animation - 1699690285924.json'
import successgif from '../../assets/images/payments/Congratulations.json'
import { useNavigate } from 'react-router';
import UseAxios from '../../util/UseAxios';

function PayStatus() {
  
    const [showepaystatus, setShowePaystatus] = useState('');
    const [spinner, setSpinner] = React.useState(true)
    const [showeMsg, setshoweMsg] = useState("");
    const [swalProps, setSwalProps] = useState({});
    const {merchantransaction_id ,setMerchantTransactionId}=React.useContext(userContext)
    const usertoken = localStorage.getItem('user_token')
    let storedData = localStorage.getItem('merchantTransactionId');

    const instance = UseAxios()
    

    const failure = {
      loop: true,
      autoplay: true,
      animationData: failuregif,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }

   
    const success = {
      loop: true,
      autoplay: true,
      animationData: successgif,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }
    useEffect(()=>{
      fetchata()
    },[])

  // console.log(merchantransaction_id);
  

  console.log(showepaystatus);

//aatiq
  // const _courseId =localStorage.getItem("courseid")
  // console.log(`courseID is ${_courseId} and their status is ${showepaystatus} `)

const[statusData,setStatusData]=useState()
const fetchata = async()=>{
  setSpinner(true)
  
try{
    const data = await instance.get(`/api/v1/courses/transaction/checkpaymentstatus/${storedData}`)
      
    
   
    // setShowestatus(data.data.message)
    // setShowePaystatus(data.data.success === true ? "success" : "failed")
    sendstatus(data.data.success ? "success" : "failed")
    console.log(data.data.success);
    console.log(data.data);
    setshoweMsg(data.data.message)
    // if(data.data.success === true){
    //   window.location.href = 'https://navyaedu.com/success';
    // }
    // if(data.data.success === false){
    //   window.location.href = 'https://navyaedu.com/failure';
    // }
    // setSwalProps({
    //     show: true,
    //     title: 'Payment Status',
    //     text: data.data.message,
    // });
    // setStatusData(data.data.message)
    // toast(data.data.message)
}catch(error){
  // window.location.href = 'https://navyaedu.com/failure';
  setSpinner(false)
  setShowePaystatus("failed" )
  // sendstatus("failed")
  console.log(error)
  setshoweMsg(error.response.data.message)
}

}


const navigate=useNavigate()
const hitApi =(e)=>{
// e.preventDefault()
  // alert('you hit a api')
  console.log('you also hit a api')
  navigate('/')
}


function sendstatus(status_send){
  setSpinner(true)
  let orderidlocal = localStorage.getItem('orderid');
 
  console.log(orderidlocal)
  console.log(status_send)
  const sendstatusdata={
    status:status_send,
  }
  
    instance
      .patch(`/api/v1/courses/order_place/${orderidlocal}`,sendstatusdata)
      .then((response) => {
        console.log(response.data.updatedData.status);
        setShowePaystatus('success')
        setSpinner(false)
        
      })
      .catch((error) => {
        setSpinner(false)
        console.log(error);
        console.log( "status not send ");
        
      })

}

function removeLocalids(){
  localStorage.removeItem("merchantTransactionId")
  localStorage.removeItem("orderid")
}

  return (
    <>
     <ToastContainer />
     {spinner && (
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="#fff"
          wrapperClass="loader"
        />
      )}
      {/* <Navbar /> */}
      {/* <SweetAlert2 {...swalProps} /> */}

      { showepaystatus === "success"
      ?
        (<div className='flex w-[100vw] justify-center h-[100vh] items-center '>
        <div className='paymentsucces'>
                <div>
                    <h3>Success</h3>
                    <p>{showeMsg}</p>
                    {/* <img className='m-auto' src='../payment/paymentsucces.png' alt="" /> */}
                    <Lottie options={success} isClickToPauseDisabled={true} height="50%" width="50%" />
                    <div class=" mt-8 ">
                        <a href='https://navyaedu.com/myorder'>
                            <button onClick={removeLocalids} class="bg-[#66BE5E] w-1/4  py-3  rounded text-white btn_hover"
                         
                            >Done</button>
                        </a>
                    </div>
                </div>
            </div>
            </div>)

        :
        
        (<div className='flex w-[100vw] justify-center h-[100vh] items-center bg-red-200'>


        <div className='paymentsucces'>
        <div >
            <h3 >Falied</h3>
            <p>{showeMsg}</p>
            {/* <img className='m-auto' src='../payment/paymentfals.png' alt="" /> */}
            <Lottie options={failure} isClickToPauseDisabled={true} height="50%" width="50%" />
            <div class="my-12 ">
                <a href='https://navyaedu.com/order_details'>
                    <button class="bg-[#f00] w-1/4  py-3  rounded text-white btn_hover"
                    
                    >Done</button>
                </a>
            </div>
        </div>
    </div>
    </div>)
    
  
      }
       
    </>
  )
}

export default PayStatus