import React from "react";
import axios from "axios";
import { Audio } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { BsCheckCircle } from 'react-icons/bs'

import Dialog from '@mui/material/Dialog';
import ReCaptcha from "../recaptcha/ReCaptcha";
import { userContext } from "../../Context/UserContextProvider";
import Capnotverify from "../recaptcha/Capnotverify";
import UseAxios from '../../util/UseAxios'
import countrylist from '../../util/country.json'
function ConatctUsForm(props) {
  const [spinner, setSpinner] = React.useState(false);
  let [responseData, setResponseData] = React.useState("");
  let [errorData, setErrorData] = React.useState("");
  const {capVal,setCapval ,mode}=React.useContext(userContext)
  const usertoken = localStorage.getItem('user_token');
  const instance = UseAxios()

  React.useEffect(() => {
    const fetchData = async () => {
      // setSpinner(true)

      try {
        const response = await instance.get("/api/v1/app/contact-us");
        console.log(response.data);
        setResponseData(response.data.contact_us);
        // setSpinner(false)
      } catch (error) {
        // setSpinner(false)
        setErrorData(error);
      }
    };

    fetchData();
  }, [usertoken]);

  const [firstName, setFname] = React.useState("");
  const [lastName, setLname] = React.useState("");
  const [userMail, setEmail] = React.useState("");
  const [userMobNo, setNumber] = React.useState("");
  const [query, setTextarea] = React.useState("");
  const ContectDetail = {
    firstName,
    lastName,
    userMail,
    userMobNo,
    query,
  };
  const [contactresponse, setContactresponse] = React.useState('');
  // const [contactdetail, setContectdetail] = React.useState(ContectDetail);

  // useEffect(() => {
  //   if (!data) return;

  //   setContectdetail(data);
  // }, [data]);

  // console.log(ContectDetail);

  const handleForm = async (event) => {
    event.preventDefault();
    console.log(capVal)
    if(capVal != null && capVal != ''){

    
    let baseURL = 'https://backend.navyaedu.com/'
    setSpinner(true);
  
    try{
      const config = {
        method: 'POST',
        baseURL: baseURL,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(ContectDetail)
    }

    const response = await fetch(`${baseURL}/api/v1/app/query`, config)
    console.log(response)
    if(response.ok){
      const data =  await response.json()
      // toast.success('Send Message Successfully');
      setContactresponse(data.msg)
      console.log(data);
      setOpen(true)
      setNumber("");
      setFname("");
      setLname("");
      setEmail("");
      setTextarea("");
      setCapval('')
      // window.location.reload()
      
  }
  else {
      toast.error(`ERROR : ${response.status}  ${response.statusText}`);
      
    }
    // console.log(data.msg);

    }catch(error){
      setSpinner(false);
      toast.error(error)
      console.log(error)}
      setCapval('')

    }
    else{
      Capnotverify("Please Verify Captcha")
    }

    
   
      
      setTimeout(() => {
        setSpinner(false);
        
      }, 1000);
  };


  // text me only 10 number print krvane ke leye (nitesh)*

  const [isValid, setIsValid] = React.useState(true);

  const handleChange = (e) => {
    let inputValue = e.target.value;

    // Trim the input if it exceeds 10 characters.
    if (inputValue.length > 10) {
      inputValue = inputValue.slice(0, 10);
    }

    // Use a regular expression to validate 10-digit numbers.
    const isValidInput = /^\d{10}$/.test(inputValue);

    setNumber(inputValue);
    setIsValid(isValidInput);
  };

  function changetextinp(e, value) {
    let inptextvalue = e.target.value;
    if (value === "Fname") {
      setFname(inptextvalue);
    }
    if (value === "Lname") {
      setLname(inptextvalue);
    }
    if (value === "email") {
      setEmail(inptextvalue);
    }
    if (value === "textarea") {
      setTextarea(inptextvalue);
    }
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
 
  return (
    <>
      {spinner && (
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass="loader"
        />
      )}
      <ToastContainer />

      <div className="md:basis-1/2 md:p-10 p-7 w-full">
        <div className="">
          <h1 className="text-center font-bold text-2xl" style={{color:mode=="dark"&&"white"}}> Send Us Message</h1>
        </div>
        <form className="pt-5 home-contactus" onSubmit={handleForm} >
          <div className="flex justify-center items-center flex-col lg:flex-row md:flex-column">
            <div className=" my-2 w-full">
              <label
              style={{color:mode=="dark"&&"white"}}
               className="block text-[#775EA5]">First Name</label>
              <input
              style={{color:mode=="dark"&&"white"}}
                value={firstName}
                onChange={(e) => changetextinp(e, "Fname")}
                type="text"
                required
                className="rounded p-3 border border-[#cabde2] w-full bg-[transaparent]"
                placeholder="First Name"
              />
            </div>
            <div className="w-full ml-2">
              <label style={{color:mode=="dark"&&"white"}}
              className="block text-[#775EA5]">Last Name</label>
              <input
              style={{color:mode=="dark"&&"white"}}
                value={lastName}
                type="text"
                onChange={(e) => changetextinp(e, "Lname")}
                required
                className="rounded p-3 w-full border border-[#cabde2] bg-[transaparent]"
                placeholder="Last Name"
              />
            </div>
          </div>
          <div className="flex justify-center items-center flex-col lg:flex-row md:flex-column">
            <div className=" my-2 w-full">
              <label 
              style={{color:mode=="dark"&&"white"}}
              className="block text-[#775EA5]">Email Address</label>
              <input
              style={{color:mode=="dark"&&"white"}}
                value={userMail}
                type="email"
                onChange={(e) => changetextinp(e, "email")}
                required
                className="rounded p-3 border border-[#cabde2] w-full bg-[transaparent]"
                placeholder="Email"
              />
            </div>
            <div className="w-full ml-2 relative">
              <div style={{color:mode=="dark"&&"white"}} className=" flex justify-between  text-[#775EA5] w-[100%] border-solid-red">
                Mobile
                {!isValid && (
                  <span style={{color:mode=="dark"&&"white"}} className="text-red-400 pe-2 pt-2 inline-block text-[10px] text-end font-bold">
                    Please enter a valid 10-digit number.
                  </span>
                )}
              </div>
              <input
             
                type="number"
                value={userMobNo}
                onChange={handleChange}
                style={{
                  padding: "12px 10px 12px 85px",
                  color:mode=="dark"&&"white",
                  overflow: "hidden",
                  borderColor: isValid ? "initial" : "red",
                }}
                required
                className="rounded  border border-[#cabde2] w-full bg-[transaparent]"
                placeholder="Phone"
              />

              <select
                class="form-select mobile_country_code !w-[7rem]"
                aria-label="Default select example"
                style={{ marginTop: "30px", marginLeft: "3px", background:mode==="dark"&&"transparent"}}
              >
                {
                  countrylist.map((code,index)=>{
                    return(
                     
                      <option style={{color:mode=="dark"&&"white",
                       background:mode==="dark"&&"#151515"}} value={code.dial_code} key={index} selected={code.dial_code === 91 ? true : false}>{code.name}(+{code.dial_code})</option>

                    )
                  })
                }
              
              </select>
            </div>
          </div>
          <div className="mx-0 md:mx-3 my-2 w-full">
            <label 
            style={{color:mode=="dark"&&"white"}}
            className="text-[#775EA5]">Your Message</label>
            <textarea
              value={query}
              style={{color:mode=="dark"&&"white"}}
              onChange={(e) => changetextinp(e, "textarea")}
              required
              className="border p-3  border-[#cabde2] w-full rounded"
              rows={5}
              placeholder="Enter Your Query"
            ></textarea>
          </div>
          <ReCaptcha/>
          <div className="text-center">
            <button
              className="bg-[#66BE5E] py-3 px-4 mt-6 rounded text-white font-semibold  btn_hover"
              type="submit"
              
            >
              Let's Connect Together
            </button>
          </div>
        </form>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className=""
      >
         <div className="bg-white px-10 pb-3 pt-3 sm:p-6 sm:pb-4 w-96">
                    <div className="success_edit_profile">
                      <BsCheckCircle className="icons" />
                      <h4>Success! </h4>
                      <p>{contactresponse}!</p>
                    </div>

                    <div className="Personal_Info_div ">
                      <div>
                        
                          <div className="text-center">
                            <button
                              className="bg-[#66BE5E] py-3 mt-7 rounded text-white font-semibold w-[90%] m-auto"
                              onClick={handleClose}
                            >
                              Close
                            </button>
                          </div>
                        
                      </div>
                    </div>
                  </div>
       
      </Dialog>
    </>
  );
}

export default ConatctUsForm;
