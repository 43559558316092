import React, { Fragment, useRef, useState } from 'react'
import LoginNavbar from '../LoginNavbar/LoginNavbar'
import Footer from '../Footer/Footer'

import refer_star from '../../assets/images/refer-star.png'
import events1 from '../../assets/images/events1.png'
import events2 from '../../assets/images/event2.png'
import Oval1 from '../../assets/images/Oval1.png'
import Oval2 from '../../assets/images/Oval2.png'
import Oval3 from '../../assets/images/Oval3.png'
import no_events from '../../assets/images/no-events.png'
import axios from 'axios'
import { Audio } from 'react-loader-spinner'

import ReactSwitch from 'react-switch'
import { BiCopy } from 'react-icons/bi'
import { HiLocationMarker } from 'react-icons/hi'

import { Dialog, Transition } from '@headlessui/react'
import { userContext } from '../../Context/UserContextProvider'
// import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

const Events = () => {
  const {profiledata, setProfiledata,mode}=React.useContext(userContext)
  // ========refer and history toggle button============
  const [checked, setChecked] = useState(true)
  const handleChange = (val) => {
    setChecked(val)
  }

  // refer modal
  const [open, setOpen] = useState(false)
  const handleShow = (e) => setOpen(true)

  const cancelButtonRef = useRef(null)

  const [spinner, setSpinner] = React.useState(false)
  let [responseData, setResponseData] = React.useState('')
  let [errorData, setErrorData] = React.useState('')

  const usertoken =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDcxODEwYWJhMzc0OGZmZDc4ZjAwN2IiLCJyb2xlIjoidXNlciIsImlhdCI6MTY4NTI1MTUzOSwiZXhwIjoxNjg3ODQzNTM5fQ.5V86CNEz3a_OEzpCMaAF4b5bW-vhigMqUi7a_LIugBQ'

  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true)

      try {
        const instance = axios.create({
          baseURL: 'https://backend.navyaedu.com/',
          headers: {
            Authorization: 'Bearer ' + usertoken,
          },
        })

        const response = await instance.get('/api/v1/app/event')
        console.log(response.data.events)
        setResponseData(response.data.events)
        setSpinner(false)
      } catch (error) {
        setSpinner(false)
        setErrorData(error)
      }
    }

    fetchData()
  }, [usertoken])

  return (
    <>
      {spinner && (
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass="loader"
        />
      )}

      <LoginNavbar />
      <div className="w-[90%] m-auto py-10 events_main_div">
        <div className="pt-2">
          <h1 className="refer_heading">Events</h1>
        </div>

        <div>
          <div className="login_with_row mt-5">
            <p className="filter_heading" style={{color:mode=="dark" ?"white":"black"}}>
              Filter:
              <label   style={{ marginLeft: '10px' }}>Upcoming </label>
              <ReactSwitch
                checked={checked}
                onChange={handleChange}
                className="toggle_btn"
              />
              <label>Past Events</label>
            </p>
          </div>
          <div>
            {checked ? (
              <div>
                {/* =========PAST EVENTS ========== */}
                <div className="row popular_cources_main_section">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="No_Past_Event_card">
                      <img src={no_events} alt="" className="img-fluid" />
                      <h2 style={{color:mode=="dark" ?"white":"black"}}>No Past Event</h2>
                      <p  style={{color:mode=="dark" ?"white":"black"}}>Lorem ipsum dolor sit amet, consectetur </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {/* Upcoming */}

                <div className="row popular_cources_main_section TrendingCourse_main_section">
                  {Array.isArray(responseData) ? (
                    responseData.map((events) => (
                      <div className="col-lg-3 col-md-4 col-sm-12">
                        <div className="card events_card" style={{color:mode=="dark" ?"white":"black", background:mode=="dark"&&"#151515", paddingLeft:"10px"}}>
                          <div className="card__inner">
                            <div className="card__front">
                              <div className="">
                                <div className="relative">
                                  <div>
                                    <img
                                      src={events.event_image}
                                      className="w-full"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="py-2">
                                  <p style={{color:mode=="dark" ?"white":"black"}} className="courses_card_heading">
                                    {events.event_title}
                                  </p>
                                </div>
                                <div className="events_date_div">
                                  <p style={{color:mode=="dark" ?"white":"black"}}>Date : {events.event_delivery_time}</p>
                                </div>

                                <div className="events_participants_div">
                                  <div className="events_participants_img_div">
                                    <img
                                      src={Oval1}
                                      alt=""
                                      className="participants_img"
                                    />
                                    <img
                                      src={Oval2}
                                      alt=""
                                      className="participants_img"
                                    />
                                    <img
                                      src={Oval3}
                                      alt=""
                                      className="participants_img"
                                    />
                                  </div>
                                  <div>
                                    <p>+20 going</p>
                                  </div>
                                </div>
                                <div className="events_location_div">
                                  <p style={{color:mode=="dark" ?"white":"black"}}>
                                    {' '}
                                    <HiLocationMarker className="icons" />
                                    {events.event_location_name}{' '}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>
                      {/* =========PAST EVENTS ========== */}
                      <div className="row popular_cources_main_section">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="No_Past_Event_card">
                            <img src={no_events} alt="" className="img-fluid" />
                            <h2 style={{color:mode=="dark" ?"white":"black"}}>No Past Event</h2>
                            <p style={{color:mode=="dark" ?"white":"black"}}>Lorem ipsum dolor sit amet, consectetur </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="refer_modal">
                      <img src={refer_star} className="img-fluid" />
                      <h2>Refer & Earn Up to 150 Points</h2>
                      <p>Earn 50 Points Every Time a referred Friend </p>
                      <button className="copy_btn">
                        Onraingn125 <BiCopy className="icons" />{' '}
                      </button>
                      <h4>Refer Your Friends, Earn Cashback</h4>
                      <div>
                        <button className="Invite_earn_green_btn ">
                          Invite & Earn
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Footer></Footer>
    </>
  )
}

export default Events
