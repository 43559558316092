import React, { Fragment, useEffect, useRef, useState } from "react";
import { RiLockPasswordLine } from "react-icons/ri";
import { AiOutlineMessage, AiOutlineStar } from "react-icons/ai";
import {
  BsShieldCheck,
  BsInfoCircle,
  BsTicketPerforated,
} from "react-icons/bs";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { BsCheckCircle, BsFillMoonFill, BsFillSunFill } from "react-icons/bs";
import { FaLongArrowAltRight } from "react-icons/fa";
import { HiOutlineLogout, HiOutlineDocumentReport } from "react-icons/hi";
import Slider from "@mui/material/Slider";
import { Dialog, Transition } from "@headlessui/react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import danger from "../../assets/images/Danger.png";
import Logout from "../../assets/images/Logout.png";

import axios from "axios";
import { Audio } from "react-loader-spinner";

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactHtmlParser from "html-react-parser";
import { BsChevronDown } from "react-icons/bs";

import { userContext } from "../../Context/UserContextProvider";
import ReCaptcha from "../recaptcha/ReCaptcha";
import Capnotverify from "../recaptcha/Capnotverify";
import Raiseticket from "./Raiseticket";
import Dataminimization from "./Dataminimization";
import Privacypolicy from "./Privacypolicy";
import Faq from "./Faq";
import TermsService from "./TermsService";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const MenuDetails = () => {
  const { capVal, mode, setMode } = React.useContext(userContext);
  const usertoken = localStorage.getItem("user_token");
  const url = new URL(window.location.href);
  const [urlopen, setUrlOpen] = useState("termservice");
  console.log(urlopen);

  useEffect(() => {
    changeurl();
  }, [url]);

  function changeurl() {
    // Check if the 'page' parameter exists in the URL
    if (url.searchParams.has("page")) {
      setUrlOpen(url.searchParams.get("page"));
    } else {
      console.error("'page' parameter not found in the URL");
    }
  }

  // password change Success
  const [Successopen, setSuccessOpen] = useState(false);
  const SuccesshandleShow = (e) => {
    return e.preventDefault(), setSuccessOpen(true);
  };

  const SuccesshandleClose = (e) => {
    return e.preventDefault(), setSuccessOpen(false);
  };
  const cancelButtonRef = useRef(null);

  let [prfresponseData, setprfResponseData] = React.useState("");
  let [prferrorData, setprfErrorData] = React.useState("");
  let [oldpass, setOldpass] = React.useState("");
  let [newpass1, setNewpass1] = React.useState("");
  let [newpass2, setNewpass2] = React.useState("");

  const bodyref_json = {
    old_password: oldpass,
    new_password: newpass1,
  };

  const instance = axios.create({
    baseURL: "https://backend.navyaedu.com/",
    headers: {
      Authorization: "Bearer " + usertoken,
    },
  });

  const navigate = useNavigate();

  const prfSubmit = (event) => {
    event.preventDefault();
    if (capVal != null) {
      setSpinner(true);
      // https://backend.navyaedu.com/api/v1/auth/profile/update-profile
      if (newpass1 === newpass2) {
        instance
          .patch("/api/v1/auth/profile/update-password", bodyref_json)
          .then((response) => {
            setSpinner(false);
            toast.success(response.data.msg);
            setprfResponseData(response.data);
            console.log(response.data.msg)
            // setTimeout(() => {
            SuccesshandleShow(event);
            // }, 2000);
            setOldpass("");
            setNewpass1("");
            setNewpass2("");
          })
          .catch((error) => {
            console.log(error);

            setSpinner(false);

            if (error) {
              setprfErrorData(error.response);
              toast.error(error.response.data.msg);
            }
          });
      } else {
        toast.error("password doesn't match");
        setSpinner(false);
      }
    } else {
      Capnotverify("Please Verify Captcha");
    }
  };

  let [deacresponseData, setdeacResponseData] = React.useState("");
  let [deacerrorData, setdeacErrorData] = React.useState("");
  let [rsn, setRsn] = React.useState("");
  let [deactivedec, setDeactiveDec] = React.useState("");

  const bodydeac_json = {
    account_deactivation_reason: rsn,
    account_deactivation_description: deactivedec,
  };
  const deacSubmit = (event) => {
    event.preventDefault();
    setSpinner(true);
    // https://backend.navyaedu.com/api/v1/auth/profile/update-profile
    instance
      .patch("/api/v1/auth/profile/deactivate-account", bodydeac_json)
      .then((response) => {
        setSpinner(false);

        setprfResponseData(response.data);
        console.log(response.data);
        toast.success("Account Deativated Successfully");
        setTimeout(() => {
          setLogouteOpen(true);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);

        setSpinner(false);

        if (error) {
          setprfErrorData(error.response);
          toast.error(error.response.data.msg);
        }
      });

    event.preventDefault();
    localStorage.clear();
    navigate("/signIn");
  };

  const [value, setValue] = React.useState(0);

  const [spinner, setSpinner] = React.useState(false);
  let [responseData, setResponseData] = React.useState("");
  let [errorData, setErrorData] = React.useState("");

  // const usertoken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDcxODEwYWJhMzc0OGZmZDc4ZjAwN2IiLCJyb2xlIjoidXNlciIsImlhdCI6MTY4NTI1MTUzOSwiZXhwIjoxNjg3ODQzNTM5fQ.5V86CNEz3a_OEzpCMaAF4b5bW-vhigMqUi7a_LIugBQ';

  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true);

      try {
        const instance = axios.create({
          baseURL: "https://backend.navyaedu.com/",
          headers: {
            Authorization: "Bearer " + usertoken,
          },
        });

        const response = await instance.get("/api/v1/app/privacy-policy");
        console.log(response.data);
        // setResponseData(response.data.privacy_policy)

        setResponseData({
          ...response.data.privacy_policy,
          description: response.data.privacy_policy.description.replaceAll(
            "&lt;",
            "<"
          ),
        });

        setSpinner(false);
      } catch (error) {
        setSpinner(false);
        setErrorData(error);
      }
    };

    fetchData();
  }, [usertoken]);

  let [faqresponseData, setFaqresponseData] = React.useState([]);
  let [faqerrorData, setFaqerrorData] = React.useState("");

  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true);

      try {
        const instance = axios.create({
          baseURL: "https://backend.navyaedu.com/",
          headers: {
            Authorization: "Bearer " + usertoken,
          },
        });

        const response = await instance.get("/api/v1/app/faq");
        // const data = await response.data
        console.log(response.data);
        console.log("faqCategory");
        setFaqresponseData(response.data);
        setSpinner(false);
      } catch (error) {
        setSpinner(false);
        setFaqerrorData(error);
      }
    };

    fetchData();
  }, [usertoken]);



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //   password show hide
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [showPasswords, setShowPasswords] = React.useState(false);

  const handleClickShowPasswords = () => setShowPasswords((show) => !show);

  const handleMouseDownPasswords = (event) => {
    event.preventDefault();
  };

  const [showPasswordss, setShowPasswordss] = React.useState(false);

  const handleClickShowPasswordss = () => setShowPasswordss((show) => !show);

  const handleMouseDownPasswordss = (event) => {
    event.preventDefault();
  };

  // Successopen
  const [deactivateopen, setdeactivateOpen] = useState(false);
  const deactivatehandleShow = (e) => {
    return e.preventDefault(), setdeactivateOpen(true);
  };
  const deactivatehandleClose = (e) => {
    return e.preventDefault(), setdeactivateOpen(false);
  };

  // logout
  const [Logouteopen, setLogouteOpen] = useState(false);
  const LogouthandleShow = (e) => {
    return e.preventDefault(), setLogouteOpen(true);
  };
  const LogouthandleClosedeactivate = (e) => {
    return e.preventDefault(), setLogouteOpen(false);
  };

  // logout
  const [Ticketopen, setTicketOpen] = useState(false);
  const TickethandleShow = (e) => {
    handleClose();
    return e.preventDefault(), setTicketOpen(true);
  };
  const TickethandleClose = (e) => {
    return e.preventDefault(), setTicketOpen(false);
  };
  // theme slider
  const marks = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 100,
      label: "100%",
    },
  ];

  const setting_tabs_main = {
    minWidth: "250px",
    alignItems: "flexStart",
  };
  //  Raise Your Ticket value send api to admin
  const [issue_type, setTicketissue] = useState();
  const [description, setDescription] = useState();
  const Raise_Ticket_data = { issue_type, description };
  function handlechangeTR(e, flag) {
    let inpvalue = e.target.value;
    if (flag === "issue") {
      setTicketissue(inpvalue);
    }
    if (flag === "description") {
      setDescription(inpvalue);
    }
  }

  const [ratresponse, setRaTresponse] = React.useState("");

  const TicketRase = async (event) => {
    event.preventDefault();
    let baseURL = "https://backend.navyaedu.com/";
    setSpinner(true);
    try {
      const config = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + usertoken,
        },
        body: JSON.stringify(Raise_Ticket_data),
      };

      const response = await fetch(
        `${baseURL}/api/v1/auth/raise-ticket`,
        config
      );

      console.log(response);
      if (response.ok) {
        const data = await response.json();
        setRaTresponse(data.msg);
        // toast.success('Message Successfully Sent!');
        setOpenRaiseTicket(true);
        // setTicketOpen(false);
        setTicketissue("");
        setDescription("");
      } else {
        toast.error(`ERROR : ${response.status}  ${response.statusText}`);
      }
    } catch (error) {
      toast.error(error);
      console.log(error);
    }

    setTimeout(() => {
      setSpinner(false);
    }, 2000);
  };

  // dark mode functionly autor:Nitesh
  const [isDarkMode, setIsDarkMode] = useState(false);

  // React.useEffect(() => {
  //   const body = document.body;
  //   if (isDarkMode) {
  //     body.classList.add('dark-mode');
  //   } else {
  //     body.classList.remove('dark-mode');
  //   }
  // }, [isDarkMode]);

  function colorMode(step) {
    console.log(step.target.value);

    if (step.target.value === 0) {
      setIsDarkMode(true);
      setMode("dark");
      localStorage.setItem("MODE_NAVYA", "dark");
    }
    if (step.target.value === 100) {
      setIsDarkMode(false);
      setMode("light");
      localStorage.setItem("MODE_NAVYA", "light");
    }
  }

  const [OpenRaiseTicket, setOpenRaiseTicket] = React.useState(false);

  const handleClose = () => {
    setOpenRaiseTicket(false);
    setTicketOpen(false);
  };

  return (
    <>
    {spinner && (
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass="loader"
        />
      )}
      <ToastContainer />
      <section
        className="w-[80%]"
        style={{
          height: "100vh",
          background: mode === "dark" && "black",
          scrollBehavior: "smooth",
          overflow: "scroll",
        }}
      >
        {/* ====================== Change Password start======================== */}

        {urlopen === "changepassword" && (
          <section
            value={value}
            index={0}
            className="tabs_side change_pass_tabs"
            stye
          >
            <div className="w-[100%] md:w-[100%] lg:w-[50%]  w-[40%] md:w-[100%] sm:w-[100%] m-auto change_pass_div"
            style={{background:mode=='dark'&& "#151515",
            padding:mode==="dark" && "55px 20px",
            borderRadius:mode==="dark"&&"10px"
            }}
            >
              <h2 style={{color:mode=='dark'&& "white"}}>Change Password</h2>
              {/* <p>
                Enter Your Email for the verification Process. we will send 4
                digits code to your email.
              </p> */}

              <form className="pt-5 passwordChange" onSubmit={prfSubmit}>
                <div className="mx-0 md:mx-3 my-2 w-full input_box">
                  <input
                    required
                    type={showPassword ? "text" : "password"}
                    className="rounded p-3 border border-[#cabde2] w-full"
                    placeholder="Enter your Old Password here "
                    onChange={(e) => setOldpass(e.target.value)}
                    value={oldpass}
                  />
                  <InputAdornment position="end" className="show_hide_btn">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                </div>
                <div className="mx-0 md:mx-3 my-2 w-full input_box">
                  <input
                    required
                    type={showPasswords ? "text" : "password"}
                    className="rounded p-3 border border-[#cabde2] w-full"
                    placeholder="Enter New Password here  "
                    onChange={(e) => setNewpass1(e.target.value)}
                    value={newpass1}
                  />
                  <InputAdornment position="end" className="show_hide_btn">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswords}
                      onMouseDown={handleMouseDownPasswords}
                      edge="end"
                    >
                      {showPasswords ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                </div>
                <div className="mx-0 md:mx-3 my-2 w-full input_box">
                  <input
                    required
                    type={showPasswordss ? "text" : "password"}
                    className="rounded p-3 border border-[#cabde2] w-full"
                    placeholder="Re-enter New Password here  "
                    onChange={(e) => setNewpass2(e.target.value)}
                    value={newpass2}
                  />
                  <InputAdornment position="end" className="show_hide_btn">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordss}
                      onMouseDown={handleMouseDownPasswordss}
                      edge="end"
                    >
                      {showPasswordss ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                </div>
                <ReCaptcha />

                <div className="text-center mt-7">
                  <button
                    // disabled={!capVal}
                    type="submit"
                    className="bg-[#66BE5E] py-3 mt-7 rounded text-white font-semibold w-[90%] m-auto btn_hover"

                    // onClick={SuccesshandleShow}
                  >
                    Save Changes
                  </button>
                </div>
              </form>
            </div>
          </section>
        )}

        {/* ====================== Change Password End======================== */}

        {/* ====================== Terms and Condition start======================== */}

        {urlopen === "termservice" && (
          <TermsService mode={mode} />
        )}

        {/* ====================== Terms and Condition End======================== */}

        {/* ====================== Privacy Policy start======================== */}

        {urlopen === "privacypolicy" && <Privacypolicy mode={mode} />}

        {/* ====================== Privacy Policy End======================== */}

        {urlopen === "raiseticket" && <Raiseticket mode={mode} />}

        {/* ====================== Select Appearance Start======================== */}

        {urlopen === "appearance" && (
          <section value={value} index={4} className=" tabs_side_gap">
            <div className="w-[100%] m-auto other_tabs_headings">
              <h2 style={{ color: mode === "dark" && "white" }}>
                Select Appearance
              </h2>
              <div
                className="Select_Appearance_div"
                style={{ background: mode === "dark" && "#151515" }}
              >
                <Slider
                style={{color:mode==="dark"&&"white"}}
                  defaultValue={mode === 'dark' ? 0 : 100}
                  aria-label="Small"
                  marks={marks}
                  valueLabelDisplay="auto"
                  step={100}
                  onChange={(step) => colorMode(step)}
                />
                <div className="mode_div">
                  <div className="mode_div_inner">
                    <BsFillMoonFill className="icons" />
                    <h4 style={{ color: mode === "dark" && "white" }}>
                      Dark Mode
                    </h4>
                  </div>
                  <div className="mode_div_inner">
                    <BsFillSunFill className="icons" />
                    <h4 style={{ color: mode === "dark" && "white" }}>
                      Sytem Default
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {/* ====================== Select Appearance End======================== */}

        {/* ====================== Data Minimization Start======================== */}

        {urlopen === "dataminimiazation" && <Dataminimization mode={mode} />}

        {/* ====================== Data Minimization End======================== */}

        {/* ====================== FAQ'S Start======================== */}

        {urlopen === "faq" && <Faq mode={mode}/>}
        {/* ====================== FAQ'S End======================== */}
      </section>
    </>
  );
};

export default MenuDetails;
