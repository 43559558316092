import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FaShareAlt } from "react-icons/fa";
import axios from "axios";
import { Audio } from "react-loader-spinner";
import LoginNavbar from "../components/LoginNavbar/LoginNavbar";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import parse from "html-react-parser";
import { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { userContext } from "../Context/UserContextProvider";
import ScholarshipTestPage from './ScholarshipTestpage'
import UseAxios from '../util/UseAxios'
function ScholarshipTest(props) {

  const {mode}=React.useContext(userContext)
  const usertoken = localStorage.getItem("user_token");
  const [spinner, setSpinner] = React.useState(false);
  const params = new URLSearchParams(window.location.search);
  const [quiz, setQuiz] = React.useState(params.get("quiz_id"));
  let [quizresponseData, setquizResponseData] = React.useState({});
  let [quizerrorData, setquizErrorData] = React.useState("");

  const instance =UseAxios()

  const [quizdetails, setQuizDetails] = useState();
  useEffect(() => {
    
    const fetchQuizdetails = async () => {
      setSpinner(true);

      try {
        const response = await instance.get(
          "/api/v1/quizzes/quiz/get-quiz-attempt-details/" + quiz
        );
        let resdata = response.data.data;
        console.log(resdata);
        setQuizDetails(resdata);
        // setquizResponseData(response.data.quiz)
        setSpinner(false);
      } catch (error) {
        setSpinner(false);
        setquizErrorData(error);
      }
    };
    fetchQuizdetails();
  }, []);

  React.useEffect(() => {
    // const sendstatus = {
    //   is_quiz_live: false,
    // };
    // const sendquizstatus = async () => {
    //   const response = await instance.patch(
    //     `/api/v1/quizzes/quiz/update_quiz_status/${quiz}`,
    //     sendstatus
    //   );
    //   console.log(response);
    //   }

    //   sendquizstatus()

    const fetchData = async () => {
      setSpinner(true);

      try {
        const response = await instance.get(
          "/api/v1/quizzes/single-quiz/" + quiz
        );
        const data = response.data.quiz
        const convertedData = {
          ...data,
          quiz_description: data.quiz_description.replace(/&lt;/g, '<'),
          quiz_name: data.quiz_name.replace(/&lt;/g, '<'),
          quiz_terms_and_condition: data.quiz_terms_and_condition.replace(/&lt;/g, '<'),
          // series_name: data.of_course_series.series_name.replace(/&lt;/g, '<'),
          // of_course_series: {
          //   ...data.of_course_series,
          //   series_name: data.of_course_series.series_name.replace(/&lt;/g, '<')
          // },

          of_plan: {
            ...data.of_plan,
            of_course_series: {
                ...data.of_plan.of_course_series,
                series_name: data.of_plan.of_course_series.series_name.replace(/&lt;/g, '<')
            }
        }
        };
        console.log(convertedData);
        setquizResponseData(convertedData);
        setSpinner(false);
      } catch (error) {
        setSpinner(false);
        setquizErrorData(error);
        console.log(error)
      }
    };

    fetchData();
  }, [usertoken]);

  const handleCourse = (courseId) => {
    localStorage.setItem("SectionId", courseId);
  };

  const [isCheckboxChecked, setIsCheckboxChecked] = React.useState(false);
  const [Visit, setVisit] = React.useState([]);
  const navigate = useNavigate();

  const sendstatus = {
    is_quiz_live: true,
  };
  const sendquizstatus = async () => {
    try {
      const response = await instance.patch(
        `/api/v1/quizzes/quiz/quiz-attempt-details/${quiz}`,
        sendstatus
      );
      console.log(response.data);
      toast.success(response.data.message);
      // Navigate to the startTest page only if the checkbox is checked
      navigate("/startTest?quiz_id=" + quizresponseData._id);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const fetchData = async () => {
    try {
      const response = await instance.get("/api/v1/quizzes/section/" + quiz);
      console.log(response.data.sections);
      sendquizstatus();
      setVisit(response.data.sections.quiz_section_questions);
    } catch (error) {
      console.log(error);
    }
  };

  const CheckExamStatus = () => {
    const{attempt_count,max_attempts}=quizdetails
    if (!isCheckboxChecked) {
      toast.error("Please agree to the Terms and Conditions.");
      return;
    }
    console.log(Visit);
    if (attempt_count < max_attempts) {
      fetchData();
    } else {
      toast.error("No More Attempts Left!");
    }
    localStorage.removeItem("queData");
  };

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    console.log("clicked");
    setIsOpen(true);
  }
  return (
    <>
      {spinner && (
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass="loader"
        />
      )}

      <ToastContainer />

      <LoginNavbar></LoginNavbar>

      <section className="courses_confirmation_card_main_section h-[89.5vh]">
        <div className="md:p-12 p-5 ">
          <div className="my-0 p-10 bg-[#b1b1b122] rounded-lg">
            <div className="flex md:justify-between justify-center md:items-end items-center flex-col md:flex-row">
              <div className="basis-1/2">
                <div className="py-2">
                  <div className="flex">
                    <h1 className="font-bold text-2xl mr-5 series_font">
                      ScholarShip Test
                    </h1>
                    {/* <img
                      src={require(`../../assets/images/MycourseTag/${1}.png`)}
                      alt=""
                      style={{ width: "35px", height: "35px" }}
                    /> */}
                  </div>
                  <div className="flex flex-col md:flex-row series_font">
                    <p className="series_font">
                      Break Between sections :{" "}
                      5 Min
                    </p>
                    <p className="md:px-2 series_font">
                      <span>
                        (1 Sections)
                      </span>
                    </p>
                  </div>
                  <p className="font-semibold md:py-2 series_font">
                    Plan Duration: 10 Min
                  </p>
                  <div className="font-light text-[grey]">
                    <p style={{color:mode==="dark"&&"white"}}>Max Attempts: 12</p>
                    <p style={{color:mode==="dark"&&"white"}}>Your Attempts: 9</p>
                  </div>
                </div>
                <div>
                  <div className="py-2 font-normal text-[#7D7D7D]">
                    <p className="d-flex items-center" style={{color:mode==="dark"&&"white"}}>
                      <span className="px-2">
                        <BsArrowRight className="font-light text-[grey]" />
                      </span>

                      {/* <p>{parse(`${bannerData[0]?.content1.replaceAll('&lt;', '<')}`)}</p> */}

                      <span style={{color:mode==="dark"&&"white"}}>
                      Test Description
                      </span>
                    </p>
                  </div>
                  
                  <div className="py-2 text-[.8rem] font-semibold text-[#7D7D7D]">
                    <p className="d-flex items-center" style={{color:mode==="dark"&&"white"}}>
                      <span className="series_font">
                        <input
                          type="checkbox"
                          Required
                          name="tnc"
                          checked={isCheckboxChecked}
                          onChange={(e) =>
                            setIsCheckboxChecked(e.target.checked)
                          }
                        />
                      </span>
                      <span className="pl-2 series_font">
                        I Agree with Navya
                      </span>

                      <MyModal_two
                      mode={mode}
                        content={quizresponseData?.quiz_terms_and_condition}
                        setIsOpen={setIsOpen}
                        isOpen={isOpen}
                        openModal={openModal}
                        closeModal={closeModal}
                      ></MyModal_two>
                      <div onClick={openModal}>
                        <span className="text-[#66BE5E] ml-1 series_font">
                          Terms And Conditions
                        </span>
                      </div>
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex lg:flex-row md:flex-column flex-col md:basis-2/5 basis-full">
                <Link
                  // to={
                  //   "/My-Purchased-plan?plan_id=" +
                  //   quizresponseData.of_plan?._id
                  // }
                  className="w-full bg-[#white] text-center text-[#775EA5] border border-[#775EA5] font-semibold rounded px-5 py-3 mb-3 lg:my-5 md:my-2 sm:my-3 btn_hover_white"
                >
                  {/* <div className='bg-[#66BE5E] text-center text-white font-semibold rounded p-2 my-5'> */}
                  <button className="series_font">Previous</button>
                  {/* </div>                */}
                </Link>

                <Link
                  className="w-full bg-[#66BE5E]  text-center text-white border border-[#775EA5] font-semibold rounded px-5 lg:m-5 md:m-0 py-3 lg:my-5 md:my-0 sm:mt-3 btn_hover "
                  // onClick={ScholarshipTestPage}
                  to="/Scholarshiptestpage"
                >
                  <button className="series_font">Start Test</button>
                </Link>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ScholarshipTest;

export function MyModal_two({ isOpen, setIsOpen, openModal, closeModal, content,mode }) {
  return (
    <>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel  style={{color:mode==="dark"&&"white", background:mode==="dark"?"black":"white"}} className="w-[60vw] transform overflow-hidden rounded-2xl p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                  style={{color:mode==="dark"&&"white"}}
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Terms & Conditions
                  </Dialog.Title>
                  <div className="mt-2">
                    <p 
                    style={{color:mode==="dark"&&"white"}}
                    className="text-sm text-gray-500">{parse(`${content}`)}</p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Got it, thanks!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
