import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Logo from '../../assets/images/logo.png'
import white from '../../assets/images/logo-white.png'
import { Link, useNavigate } from 'react-router-dom'
import { AiFillStar } from 'react-icons/ai'
import { Rating } from 'react-simple-star-rating'
import Pdf from 'react-to-pdf'
// import axios from 'axios'
import { Audio } from 'react-loader-spinner'
import Weakchart from './Testreport/Weakchart'
import back_btn from '../../assets/images/back-btn.png'
import { HiArrowNarrowRight } from 'react-icons/hi'
import XYZreportChart from './Testreport/XYZreportChart'
import Sectionchart from './Testreport/Sectionchart'
import PieChartReport from './Testreport/PieChartReport'
import PieChartnotfill from './Testreport/PieChartnotfill'
import { useContext } from 'react'
import { userContext } from '../../Context/UserContextProvider'
import UseAxios from '../../util/UseAxios'
import NoCourses from '../new/NoCourses'
import ReactHtmlParser from 'html-react-parser';
import { toast } from "react-toastify";
const RADIAN = Math.PI / 180


const TestReport = () => {
  const instance = UseAxios()
  const { showetest, setShoweTest,mode } = useContext(userContext)
  let [responseReport, setResponseReport] = React.useState([]);
  console.log(responseReport)
  let [showetable, setShowetable] = React.useState(5);
  let Navigate = useNavigate()
  // let [showetest,setShoweTest] = React.useState(localStorage.getItem("quizId"))
  // rating

  const [rating, setRating] = useState(0)
  const [spinner, setSpinner] = React.useState(false)

  // Redirect to home when the back button is clicked in browser 
// useEffect(()=>{
//   window.history.pushState(null, "", window.location.href);
//   window.onpopstate = function() {
//       window.history.pushState(null, "", window.location.href);
//   };
// },[])
window.onpopstate = function(event) {

  // window.location.href = "/";
  Navigate("/")

  
};

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate)

    // other logic
  }
  // Optinal callback functions
  // const onPointerEnter = () => console.log('Enter')
  // const onPointerLeave = () => console.log('Leave')
  // const onPointerMove = (value, index) =>
  //   console.log(value, index)

  const [Successopen, setSuccessOpen] = useState(false)
  const SuccesshandleShow = (e) => {
    return e.preventDefault(), setSuccessOpen(true)
  }

  const SuccesshandleClose = (e) => {
    return e.preventDefault(), setSuccessOpen(false)
  }
  // const cancelButtonRef = useRef(null)
  // const ref = React.createRef()

  const params = new URLSearchParams(window.location.search)

  // // const [section, setSection] = React.useState()
  // // console.log()


  const [quiz, setQuiz] = React.useState(params.get('quiz_id'))
  // const [timeTaken, setTimeTaken] = React.useState(params.get('time'))
  // const [quesection, setqueSection] = React.useState(params.get('json_qu_status'))
  // const [anssection, setansSection] = React.useState(params.get('json_ans'))
  // // console.log(JSON.parse(quesection), JSON.parse(anssection))



  // let [responseData, setResponseData] = React.useState('')
  // let [errorData, setErrorData] = React.useState('')
  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     try {


  //       const response = await instance.get(
  //         '/api/v1/quizzes/section/' + quiz,
  //       )
  //       console.log(response.data)

  //       setResponseData(response.data)
  //     } catch (error) {
  //       setErrorData(error)
  //     }
  //   }

  //   fetchData()
  // }, [usertoken])




  // const quesectionData = JSON.parse(params.get('json_qu_status'));
  // const anssectionData = JSON.parse(params.get('json_ans'));

  // // Create a function to find the status of a question ID
  // const findStatus = (questionId) => {
  //   const quesectionStatus = quesectionData[questionId] || 0;
  //   // const anssectionStatus = anssectionData[questionId] || 0;
  //   // return quesectionStatus > 0 ? quesectionStatus : anssectionStatus;
  //   return quesectionStatus;
  // };

  // // Create an array for each section containing the question ID and its status
  // const sectionDataWithStatus = responseData?.sections?.map((section) => {
  //   const sectionQuestions = section?.quiz_section_questions?.map((questionId) => {
  //     return {
  //       questionId,
  //       status: findStatus(questionId),
  //     };
  //   });
  //   return {
  //     sectionId: section._id,
  //     sectionName: section.section_name,
  //     questions: sectionQuestions,
  //   };
  // });

  // // console.log(sectionDataWithStatus);


  // const findAnswerOptions = (questionId) => {
  //   // return anssectionData[questionId] || [];
  //   return anssectionData[questionId] === 1 ? 1 : anssectionData[questionId] === 0 ? 0 : null;
  // };

  // // Create an array for each section containing the question ID and its answer options
  // const sectionDataWithAnswers = responseData?.sections?.map((section) => {
  //   const sectionQuestions = section?.quiz_section_questions?.map((questionId) => {
  //     return {
  //       questionId,
  //       answerOptions: findAnswerOptions(questionId),
  //     };
  //   });
  //   return {
  //     sectionId: section._id,
  //     sectionName: section.section_name,
  //     questions: sectionQuestions,
  //   };
  // });



  // let [repaccData, setrepaccData] = React.useState('');
  // let [responseaccData, setresponseaccData] = React.useState('');



  // const findAnswerOptionsStatus = async (questionIds) => {
  //   // Count variables
  //   let nullCount = 0;
  //   let notNullCount = 0;
  //   let answered_right = 0;
  //   let answered_wrong = 0;

  //   // Helper function to fetch answer data and update counts
  //   const fetchAnswerData = async (question) => {

  //     try {


  //       const response = await instance.get('/api/v1/quizzes/option/' + question.questionId);

  //       if (response.data.options[question.answerOptions].is_correct === true) {
  //         answered_right++;
  //       } else {
  //         answered_wrong++;
  //       }
  //     } catch (error) {
  //       // Handle error if necessary
  //     }

  //   };

  //   // const fetchAnswerData = async (question) => {

  //   //   if (!fetchAnswerData.cache) {
  //   //     try {


  //   //       const response = await instance.get('/api/v1/quizzes/option/' + question.questionId);
  //   //       fetchAnswerData.cache = response.data; 
  //   //     } catch (error) {

  //   //     }
  //   //   }


  //   //   if (fetchAnswerData.cache && fetchAnswerData.cache.options[question.answerOptions].is_correct === true) {
  //   //     answered_right++;
  //   //   } else {
  //   //     answered_wrong++;
  //   //   }
  //   // };

  //   // Create an array of promises for each API call
  //   const promises = [];

  //   for (const question of questionIds) {
  //     if (question.answerOptions !== null) {
  //       notNullCount++;
  //       promises.push(fetchAnswerData(question));
  //     } else {
  //       nullCount++;
  //     }
  //   }

  //   // Wait for all promises to resolve using Promise.all
  //   await Promise.all(promises);

  //   const countsObject = {
  //     "not_answered": nullCount,
  //     "answered": notNullCount,
  //     "answered_right": answered_right,
  //     "answered_wrong": answered_wrong,
  //     "accuracy": answered_right * 100 / (notNullCount + nullCount),
  //   };
  //   return countsObject;

  // };





  // const SectionRans = Promise.all(sectionDataWithAnswers?.map(async (sectionrans) => {
  //   const counts = await findAnswerOptionsStatus(sectionrans?.questions);

  //   return {
  //     sectionId: sectionrans?.sectionId,
  //     sectionName: sectionrans?.sectionName,
  //     questions: counts,
  //   };
  // }));

  const [resultans, setResultans] = React.useState({})
  // const [data_All_Tests, setdata_All_Tests] = React.useState({})
  // const [data_Chart_Tile, setdata_Chart_Tile] = React.useState({})

  // const [data_piechart, setdata_piechart] = React.useState()
  // const [data, setdata] = React.useState()

  // const [answeredset, setansweredset] = React.useState()
  // const [notansweredset, setnotansweredset] = React.useState()
  // const [answeredsetright, setansweredsetright] = React.useState()
  // const [notansweredsetwrong, setnotansweredsetwrong] = React.useState()


  // SectionRans.then((results) => {
  // console.log(results);
  // setResultans(results);

  // const sumOfAccuracy = results.reduce((sum, obj) => sum + obj.questions.accuracy, 0);

  // const sumOfnot_answered = results.reduce((sum, obj) => sum + obj.questions.not_answered, 0);
  // const sumOfanswered = results.reduce((sum, obj) => sum + obj.questions.answered, 0);
  // const sumOfanswered_right = results.reduce((sum, obj) => sum + obj.questions.answered_right, 0);
  // const sumOfanswered_wrong = results.reduce((sum, obj) => sum + obj.questions.answered_wrong, 0);


  // // console.log(sumOfAccuracy, results)
  // setresponseaccData(sumOfAccuracy / results.length)
  // setansweredset(sumOfanswered)
  // setnotansweredset(sumOfnot_answered)
  // setansweredsetright(sumOfanswered_right)
  // setnotansweredsetwrong(sumOfanswered_wrong)

  // // chart two
  // setdata_piechart([
  //   { name: 'Attempted Ques', value: sumOfanswered },
  //   { name: 'Unattempted Ques', value: sumOfnot_answered },
  // ]);


  // setdata([
  //   { name: 'Correct Answers', value: sumOfanswered_right },
  //   { name: 'Incorrect Answers', value: sumOfanswered_wrong },
  // ]);


  // // chart one
  // setdata_All_Tests(results?.map(option => ({
  //   name: option.sectionName,
  //   UnattemptedQues: option.questions.not_answered,
  //   AttemptedQues: option.questions.answered,
  //   CorrectAnswers: option.questions.answered_right,
  //   IncorrectAnswers: option.questions.answered_wrong,
  //   ObtainedMarks: option.questions.obtained_marks, // Adjust this property based on your data structure
  //   Accuracyin: option.questions.accuracy,
  //   amt: option.questions.answered, // Adjust this property based on your data structure
  // }))
  // );


  // setdata_Chart_Tile(results?.map(option => ({
  //   name: option.sectionName,
  //   UnattemptedQues: option.questions.not_answered,
  //   AttemptedQues: option.questions.answered,
  //   CorrectAnswers: option.questions.answered_right,
  //   IncorrectAnswers: option.questions.answered_wrong,
  //   ObtainedMarks: option.questions.obtained_marks,
  //   amt: option.questions.answered,
  // }))
  // );


  // });

  // // console.log(resultans)


  // // Calculate the average accuracy
  // // let [responseaccData, setresponseaccData] = React.useState('');
  // // const sumOfAccuracy = resultans.reduce((sum, obj) => sum + obj.accuracy, 0);
  // // setresponseaccData(sumOfAccuracy / resultans.length)

  // // Calculate the average accuracy

  // //187 246 269 344


  let [repsecresponseData, setrepsecResponseData] = React.useState('');
  let [repsecerrorData, setrepsecErrorData] = React.useState('');
  console.log(repsecresponseData)

  if (resultans.length > 0) {

    const quizReportData = {
      section_detail: resultans.map((result, index) => ({
        of_section: result.sectionId,
        time_taken: 0, // Fill with actual time taken for the section
        attempted_questions: result.questions.answered,
        unattempted_questions: result.questions.not_answered,
        correct_questions: result.questions.answered_right,
        incorrect_questions: result.questions.answered_wrong,
        marks_obtained: 0, // Fill with actual marks obtained for the section
        question_option_selected: {}, // Fill with the user's selected options for each question
      })),
    };



    const fetchData = async () => {
      try {
        const response = await instance.post('/api/v1/quizzes/report/' + quiz, quizReportData);
        console.log(response.data);

        setrepsecResponseData(response.data);
      } catch (error) {
        setrepsecErrorData(error);
      }
    };


  }




  // let [secresponseData, setsecResponseData] = React.useState('');
  // let [secerrorData, setsecErrorData] = React.useState('');

  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     try {


  //       const response = await instance.get('/api/v1/quizzes/reports/' + quiz);
  //       console.log(response.data);

  //       setsecResponseData(response.data);
  //     } catch (error) {
  //       setsecErrorData(error);
  //     }
  //   };

  //   fetchData();
  // }, [usertoken, quiz]);

  // user test report author : Nitesh 
  const sendstatus = {
    is_quiz_live: false,
  };
  const sendquizstatus = async () => {
    const response = await instance.patch(
      `/api/v1/quizzes/quiz/quiz-attempt-details/${quiz}`,
      sendstatus
    );
    console.log(response.data);
    // toast.success(response.data.message);
  };


  React.useEffect(() => {
    sendquizstatus()
  })
  React.useEffect(() => {
    const fetchData = async () => {
      setSpinner(true)
      try {


        const response = await instance.get('/api/v1/quizzes/reports');
        setSpinner(false)
        console.log(response.data.data);
        setResponseReport(response.data.data)
        localStorage.setItem("quizId", response.data.data[0].reportId)
        setShoweTest(response.data.data[0].reportId)

      } catch (error) {
        console.log(error)
        setSpinner(false)
      }
    };

    fetchData();
  }, []);

  console.log(responseReport)
  const [testid, setTestid] = useState('')

  function handleChange(e) {
    setTestid(e.target.value)

  }

  function clikTestshowe(testid) {
    console.log(testid)

    setShoweTest(testid)
  }



  let filterdata = responseReport?.find(item => item?.reportId === showetest)
  console.log(filterdata?.topicScore)
  console.log(filterdata)


  function viewMoreLess(flag){
    if(flag==="more"){

      setShowetable(showetable + 10)
    }
    if(flag==="less"){

      setShowetable(5)
    }
  }



  return (
    <>

      {spinner && (
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass={`${mode==="dark" ?"loaderdark":"loader"}`}
          
        />
      )}

      {responseReport.length
        ?
        <section className='select-none'>
          {/* <LoginNavbar></LoginNavbar> */}
          <section className="only_logo_section" style={
            {
              background:mode==="dark"&&"black"
            }
          }>
            <a href='/' >
            <img className="logo" src={mode==="dark"? white :Logo} alt="" />
            </a>
          </section>
          <section className=" only_logo_top_section Congratulations_report">

            {/* <div className="mb-4">
              <div className="report_back_btn">
                <Link to="/startTest">
                  {' '}
                  <img src={back_btn} alt="back" className="img-fluid" /> Back
                </Link>
              </div>
            </div> */}
            <div className="help_feedback_div">
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="test_card_dark_blue" style={{background:mode=="dark"?"black":"white", color:mode==="dark"&&"white"}}>
                  <div className="test_name_rate_div">
                 
                  <h1
                  style={{ color:mode==="dark"&&"white"}}
                  > {ReactHtmlParser(filterdata?.testName?.replaceAll('&lt;', '<'))}</h1>
                    <button
                      className=" !hidden RateOurCourse "
                    // onClick={SuccesshandleShow}
                    >
                      <AiFillStar className="icons" /> Rate Our Course
                    </button>
                  </div>
                  <div className="Congratulations_div">
                    <div className="Congratulations_side_content">
                      <h3 style={{ color:mode==="dark"&&"white"}} >
                        Congratulations! <br />
                        
                        <h3 style={{ color:mode==="dark"&&"white"}}   className='mb-0 inline uppercase'>{ReactHtmlParser(filterdata?.username?.replaceAll('&lt;', '<'))}</h3>, you have scored
                        {/* <span className={responseaccData > 36 ? 'span_passed' : 'span_failed'}> {resultans?.length > 0 ? responseaccData : 0}% </span> */}

                        <h3  style={{ color:mode==="dark"&&"white"}}  className='mb-0 inline uppercase '> {filterdata?.scored_Marks}<span className=''>% </span></h3>
                        in the
                        <h4 style={{ color:mode==="dark"&&"white"}}  className='mb-0 inline uppercase'> {ReactHtmlParser(filterdata?.testName?.replaceAll('&lt;', '<'))} </h4>
                        test.
                      </h3>
                      {/* <h6>
                        Nulla Lorem mollit cupidatat irure. Laborum magna nulla
                        duis ullamco cillum dolor. Voluptate ex
                      </h6>
                      <p>
                        E. Laborum magna nulla duis ullamco cillum dolor.
                        Voluptate gna nulla duis ullamco
                      </p> */}
                    </div>
                    <div></div>
                  </div>
                  <div className='test_name_rate_name'>
                    <h1 style={{ color:mode==="dark"&&"white"}}>Taken Tests:</h1>
                    <span style={{ color:mode==="dark"&&"white"}}>I Couldn’t Understand. <a href="/setting?page=raiseticket">Need Help?</a></span>
                  </div>
                  <div className='All_test_list flex gap-3 mt-7'>
                    {
                      responseReport.map((userreport, index) => {
                        return (
                          <div className='pt-7 pb-7' onClick={() => clikTestshowe(userreport?.reportId)}>
                            <div className='test_list_card'>
                              <h5 style={{ color:"white"}} className=' style={{ color:mode==="dark"&&"white"}}   style={{ color:mode==="dark"&&"white"}}   mb-0 text-center overflow-hidden'>{ReactHtmlParser(userreport?.testName?.replaceAll('&lt;', '<'))}</h5>
                            </div>
                            {/* <a className='mt-2 inline-block'>View Report_1</a> */}
                          </div>
                        )
                      })
                    }



                  </div>
                  <div className='all_text_table overflow-scroll'>
                    <table class="table table-bordered mt-8">
                      <thead>
                        <tr>
                          <th   style={{ color:mode==="dark"&&"white"}}   scope="col" className='text-center'>Test</th>
                          <th   style={{ color:mode==="dark"&&"white"}}   scope="col" className='text-center'>Attempted Ques</th>
                          <th   style={{ color:mode==="dark"&&"white"}}   scope="col" className='text-center'>Unattempted Ques</th>
                          <th   style={{ color:mode==="dark"&&"white"}}   scope="col" className='text-center'>Not visited</th>
                          <th   style={{ color:mode==="dark"&&"white"}}   scope="col" className='text-center'>Correct Ans</th>
                          <th   style={{ color:mode==="dark"&&"white"}}   scope="col" className='text-center'>Incorrect Ans </th>
                          <th   style={{ color:mode==="dark"&&"white"}}  scope="col" className='text-center'>Obtained Marks </th>
                          <th   style={{ color:mode==="dark"&&"white"}}   scope="col" className='text-center'>Accuracy in % </th>
                          <th   style={{ color:mode==="dark"&&"white"}}   scope="col" className='text-center'>Weak Section </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          responseReport.slice(0, showetable).map((userreport, index) => {
                            return (
                              <tr key={index}>
                                <td  style={{ color:mode==="dark"&&"white"}} >{ReactHtmlParser(userreport?.testName?.replaceAll('&lt;', '<'))}</td>
                                <td  style={{ color:mode==="dark"&&"white"}} >{userreport?.attempted_question}</td>
                                <td  style={{ color:mode==="dark"&&"white"}} >{userreport?.unAttempted_question}</td>
                                <td  style={{ color:mode==="dark"&&"white"}} >{userreport.not_visited.length}</td>
                                <td  style={{ color:mode==="dark"&&"white"}} >{userreport?.correct_answer}</td>
                                <td  style={{ color:mode==="dark"&&"white"}} >{userreport?.incorrect_answer}</td>
                                <td  style={{ color:mode==="dark"&&"white"}} >{userreport?.obtained_Marks}</td>
                                <td  style={{ color:mode==="dark"&&"white"}} >{userreport?.accuracy}%</td>
                                <td  style={{ color:mode==="dark"&&"white"}}  className='flex justify-center'>{userreport?.weakSection?.map((x, i) => {
                                  return (

                                    <p>{`${x}${i !== userreport.weakSection.length - 1 ? ">" : ""}`}</p>

                                  )

                                })}</td>

                              </tr>
                            )
                          })
                        }

                      </tbody>
                    </table>
                    {responseReport.length < showetable ? 
                    <div onClick={()=>viewMoreLess('less')}>
                    <a>View Less</a>
                  </div>
                    :<div onClick={()=>viewMoreLess('more')}>
                      <a>View More</a>
                    </div>}
                  </div>
                  <XYZreportChart mode={mode} report_data={responseReport} />

                  {/* All test Chart  :Bar chart */}
                  <div className="row">
                    <div className="col-lg-12">

                      <div className="test_card_dark_blue" style={{background:mode=="dark"?"black":"white", color:mode==="dark"&&"white"}}>
                        <h2 className="report_other_headings" style={{ color:mode==="dark"&&"white"}}>Progress Bar</h2>
                        <div style={{ width: '100%' }}>
                          <Sectionchart mode={mode} report_data={responseReport} />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          

            <section className="test_main_sections only_logo_top_section">
             
                <div className="row">
                  <div className="col-lg-12">
                    <div className="test_card_dark_blue" style={{background:mode=="dark"?"black":"white", color:mode==="dark"&&"white"}}>
                      <div className='flex items-center justify-between'>
                        <h2  style={{ color:mode==="dark"&&"white"}}className="report_other_headings text-[#52bb49]">Test Analysis:</h2>
                        <select 
                        style={{background:mode=="dark"?"black":"red", color:mode==="dark"&&"white"}}
                        className="form-select form-select_two" onChange={handleChange}>
                          {/* <option value="" selected disabled >Select Test Type</option> */}
                          {
                            responseReport.map((userreport, index) => {
                              return (
                                <option style={{background:mode=="dark"?"black":"white", color:mode==="dark"&&"white"}} key={index} value={userreport?.reportId} >{ReactHtmlParser(userreport?.testName?.replaceAll('&lt;', '<'))}</option>

                              )
                            })
                          }


                        </select>
                      </div>

                      <div className="w-full xl:flex mb-5 px-8">
                        <div className="w-full py-12 border text-center">
                          <PieChartReport report_data={responseReport} test_id={testid} />
                          <div className='xl:flex items-center justify-center'>
                            <div className='flex items-center mx-2'><p className='bg-[#5B9BD5] w-3 h-3 m-0 me-1'></p>Attempted Ques</div>
                            <div className='flex items-center mx-2'><p className='bg-[#ED7D31] w-3 h-3 m-0 me-1'></p>Unattempted Ques</div>
                          </div>

                        </div>
                        <div className="w-full py-12 border  text-center">
                          <PieChartnotfill report_data={responseReport} test_id={testid} />
                          <div className='xl:flex items-center justify-center'>
                            <div className='flex items-center mx-2'><p className='bg-[#A5A5A5] w-3 h-3 m-0 me-1'></p>CorrectAnswers</div>
                            <div className='flex items-center mx-2'><p className='bg-[#FFC000] w-3 h-3 m-0 me-1'></p>IncorrectAnswers</div>
                          </div>

                        </div>
                      </div>







                      <div className='pt-8 pb-12'>
                        <h2 style={{ color:mode==="dark"&&"white"}} className="report_other_headings text-[#52bb49]">Analysis Weak Topics.</h2>
                        <Weakchart report_data={filterdata?.topicScore} />

                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <div className="test_report_marks_detail_div">
                            <p>
                              <span>
                                <HiArrowNarrowRight />

                              </span>
                              {/* You Attempted {answeredset} Questions out of {answeredset + notansweredset} Questions */}
                              You Attempted {filterdata?.attempted_question} Questions out of {filterdata?.total_Questions} Questions
                            </p>
                            <p>
                              <span>
                                <HiArrowNarrowRight />
                              </span>
                              {/* You Attempted {answeredsetright} Right Answer And {notansweredsetwrong} Wrong Answer */}
                              You Attempted {filterdata?.correct_answer} Right Answer And {filterdata?.incorrect_answer} Wrong Answer
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='grafh_report_content'>
                        <div>
                          <p style={{ color:mode==="dark"&&"white"}}>{ReactHtmlParser(filterdata?.username?.replaceAll('&lt;', '<'))}, you scored {filterdata?.scored_Marks}% marks out of  {filterdata?.maximum_Marks} on the first attempt of &nbsp;
                            {ReactHtmlParser(filterdata?.testName?.replaceAll('&lt;', '<'))} on {filterdata?.test_attempted_date?.split('T')[0]}</p>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>





                <div className="row">
                  <div className="col-lg-12">

                    <div className="test_card_dark_blue test_card_right_ques_scroll" style={{background:mode=="dark"?"black":"white", color:mode==="dark"&&"white"}}>
                      <h2  style={{ color:mode==="dark"&&"white"}} className="report_other_headings">
                        Correct Answers of the <h2 className='text-[#775EA5] inline'> {ReactHtmlParser(filterdata?.testName?.replaceAll('&lt;', '<'))}</h2>
                      </h2>

                      <div className='h-[52vh] overflow-scroll'>
                        {
                          filterdata?.questionsData?.map((que_solution, index) => {
                            return (
                              <div key={index} className="single_question_box">

                                <div className="report_answer">
                                  <span  style={{ color:mode==="dark"&&"white"}} className='me-2 ms-2 font-extrabold'>{index + 1}. </span>
                                  {/* <span className="report_correct_ans bg-[#775EA5]">A</span> */}
                                  <p style={{ color:mode==="dark"&&"white"}}  className='!text-[#52bb49] m-0'>
                                    {que_solution?.questionTitle}
                                  </p>
                                </div>
                                <div className="my-2 xl:flex ">
                                  <div className="bg-[#52bb49] px-2  rounded-xl text-white text-center !mb-0">solution :</div>
                                  <p  style={{ color:mode==="dark"&&"white"}} className='text-center text-[#775EA5] mb-1 ms-2'>
                                    {que_solution?.correctOptionTitle}
                                  </p>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>



                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 weal_links_col">

                    <div 
                    style={{background:mode=="dark"?"#151515":"white", color:mode==="dark"&&"white"}}
                    className="test_card_dark_blue test_card_right_ques_scroll">
                      <h2 style={{ color:mode==="dark"&&"white"}} className="report_other_headings  ">Your Weak Points:

                      </h2>
                      <h3 style={{ color:mode==="dark"&&"white"}}>
                        Sorry! You must work smartly on the following topics.
                      </h3>
                      <ul>
                        {/* {filterdata?.weakSection?.map((x, i) => {
                          return (

                            <li style={{ color:mode==="dark"&&"white"}}>{x}</li>

                          )

                        })} */}
                         <li  style={{ color:mode==="dark"&&"white"}}  className='flex justify-left'>
                          {filterdata?.weakSection?.map((x, i) => {
                                  return (

                                    <p>{`${x}${i !== filterdata.weakSection.length - 1 ? ">" : ""}`}</p>

                                  )

                                })}</li>

                      </ul>
                    </div>
                  </div>
                </div>
                <div className="help_feedback_div px-4">
                  {/* <div>
                    <p>
                      Wao! how good it is?{' '}
                      <Link to="#" className="feedback">
                        {' '}
                        Feedback{' '}
                      </Link>
                    </p>
                  </div> */}
                  <div>
                    <Link to="/setting?page=raiseticket" className="help">

                      NEED HELP?
                    </Link>
                  </div>
                </div>
                <div className="Download_Test_Report_div xl:flex items-center  justify-center">
                {/* <Pdf targetRef={ref} filename="code-example.pdf">
        {({ toPdf }) => <button onClick={toPdf}>Generate Pdf</button>}
      </Pdf> */}
              </div>
            </section>

          </section>
        </section>
        :
        <section className='select-none'>
          <section className="only_logo_section">
            <a href='/' >
              <img src={Logo} className="img-fluid" />
            </a>
          </section>
          <div className='h-[100vh] flex justify-center items-center'>
            <NoCourses stat="Report" />
          </div>
        </section>
      }

      <Transition.Root show={Successopen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10  select-none"
          // initialFocus={cancelButtonRef}
          onClose={setSuccessOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4  ">
                    <div className="success_edit_profile">
                      <h4>Rate Our Course </h4>
                      <form className="w-full">
                        <div className="rating_star">
                          <Rating
                            className=""
                            onClick={handleRating}
                          // onPointerEnter={onPointerEnter}
                          // onPointerLeave={onPointerLeave}
                          // onPointerMove={onPointerMove}
                          // showTooltip={true}
                          // rtl={true}
                          /* Available Props */
                          />
                        </div>
                        <div className="mx-0  my-2 w-full mt-4">
                          <textarea
                            className="border border-[#cabde2] w-full rounded p-2"
                            rows={4}
                            placeholder="Enter Your Issue Topic Here"
                          ></textarea>
                        </div>
                      </form>
                    </div>

                    <div className="Personal_Info_div ">
                      <div>
                        <div className="pt-5">
                          <div className="text-center">
                            <button className="   ">
                              <Link className="text-white bg-[#66BE5E] py-3 px-5 mt-7 rounded text-white font-semibold w-[90%] m-auto">
                                Send Feedback
                              </Link>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default TestReport;