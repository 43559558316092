import React from 'react'
import LoginNavbar from '../LoginNavbar/LoginNavbar'
import Navbar from '../Header/Navbar'
import Footer from '../Footer/Footer'
import SettingMenu from '../Setting/SettingMenu'


function Setting() {
  const usertoken = localStorage.getItem('user_token')
  return (
    <>
      {!usertoken ? <Navbar /> :<LoginNavbar />}
        <SettingMenu />
      <Footer></Footer>
    </>
  )
}

export default Setting;