import Cookies from 'js-cookie';
import {useState } from 'react';
function CookiModel() {
  const [open,setOpen]=useState(Cookies.get("Navya_isCookie") ? false : true)
    // const Navigate =useNavigate()
    function handleClick(flag){
      setOpen(false)
      Cookies.set( "Navya_isCookie", true);
      if (flag=="accept_all") {
        Cookies.set( "Navya_Cookie", "ALL");
        // Cookies.set("Navya_User", "Nitesh kumawat",)
          
        }
        if (flag=="accept_necessary") {
          Cookies.set( "Navya_Cookie", "NECESSARY");

        }
    }

 


  return (
    <>
  {open && <section className='fixed bottom-0 z-[99999] p-4 left-0 bg-[#fff] shadow-lg w-full  '>
    <header>
      <h4 className='text-base font-bold'>Accept cookies </h4>
    </header>
    <div>
      <img src="https://cdn-icons-png.freepik.com/512/4787/4787962.png" alt="" srcset="" width={100}  />
    </div>
    <div>
      <p>
      By clicking "Accept all cookies", you agree Navya can store cookies on your device and disclose information in accordance with our <a className='text-blue-600 visited:text-purple-600' href=''>Cookie Policy</a>.
      </p>
    </div>
    <div className='mt-3 flex justify-end'>
      <div className='flex w-1/2 pe-16 '>
        <button type='button' onClick={()=>handleClick("accept_all")}  className="bg-[#66BE5E] py-2 px-2 w-1/2  rounded text-white font-semibold  btn_hover">Accept all cookies</button>
        <button type='button' onClick={()=>handleClick("accept_necessary")} className="bg-[#66BE5E] py-2 px-2 ms-2 w-1/2 rounded text-white font-semibold  btn_hover">Necessary cookies only</button>
      </div>
      {/* <div>
        <button onClick={handleClick} type='button'  className="border-2 border-[#775EA5] mt-2 hover:text-[#fff]  w-full py-2 px-2 rounded font-semibold  btn_hover">Cancel</button>

      </div> */}
    </div>
    </section>}
    </>
  )
}

export default CookiModel