import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import NavyaImg from '../../assets/images/loginPageSigeImg.png'
import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Audio } from 'react-loader-spinner'
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from 'firebase/app';
import { BiMaleFemale } from "react-icons/bi";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  getAuth,
} from 'firebase/auth';
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import ReCaptcha from '../recaptcha/ReCaptcha'
import Capnotverify from '../recaptcha/Capnotverify'
import { userContext } from '../../Context/UserContextProvider'
import CountryList from '../../util/country.json'
import Swal from 'sweetalert2'
import Cookies from 'js-cookie'
import UseAxios from '../../util/UseAxios'
// Add this code outside your component (e.g., at the top of the file)
const firebaseConfig = {
  apiKey: "AIzaSyAVi1PEi-NYtkhfFz-gc2SG6cMHP5gZHdw",
  authDomain: "navya-tlm.firebaseapp.com",
  projectId: "navya-tlm",
  storageBucket: "navya-tlm.appspot.com",
  messagingSenderId: "866685260976",
  appId: "1:866685260976:web:d16ec1cdd436a2927ed775",
  measurementId: "G-88MHETWSTQ"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);


function SignUp() {

  const location = useLocation()
  console.log(location)

  const usertoken = localStorage.getItem('user_token')
  const { capVal, setCapval, setAccess_token } = React.useContext(userContext)
  // const {capVal,access_token ,setAccess_token ,mode}=React.useContext(userContext)

  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const [showcoPassword, setShowcoPassword] = React.useState(false)

  const handleClickShowcoPassword = () => setShowcoPassword((show) => !show)

  const handleMouseDowncoPassword = (event) => {
    event.preventDefault()
  }

  const today = new Date().toISOString().split('T')[0];

  let [responsecountryData, setcountryResponseData] = React.useState(CountryList)
  let [errorcountryData, setcountryErrorData] = React.useState('')

  // const instance = axios.create({
  //   baseURL: 'https://backend.navyaedu.com/',
  //   // baseURL: 'http://192.168.1.12:9000/',
  //   headers: {
  //     'content-type': 'application/json',
  //     Authentication: 'HJGJHGSJHAGJGJSHAGSJAHJSHAJHKHKJHAKH',
  //   },
  // })
  const instance = UseAxios()

  let [responseData, setResponseData] = React.useState('')
  let [errorData, setErrorData] = React.useState('')
  let [namel, setNamel] = React.useState(location?.state?.data?.name)
  let [namer, setNamer] = React.useState(location?.state?.data?.lastname)
  let [email, setEmail] = React.useState(location?.state?.data?.email)
  let [dob, setDob] = React.useState(location?.state?.data?.birthday)
  let [pass, setPass] = React.useState('')
  let [copass, setcoPass] = React.useState('')
  let [mobile, setMobile] = React.useState(location?.state?.data?.phone_no)
  let [gender, setGender] = React.useState('Male')
  let [countryMobile, setcountryMobile] = React.useState(location?.state?.data?.country_code)
  const [spinner, setSpinner] = React.useState(false)
  const [dataname, setDataname] = useState({})
  console.log(dataname);

  const handleCountryChange = (e) => {
    setcountryMobile(e.target.value);
  };

  function inptextset(e, inptext) {
    let key = e.target.name
    let value = e.target.value

    setDataname((pre) => ({ ...pre, [key]: value }))
    // setDataname((pre)=>({...pre,[e.target.name]:e.target.value}))

    if (inptext === "namel") {
      setNamel(e.target.value)
    }
    if (inptext === "namer") {
      setNamer(e.target.value)
    }
    if (inptext === "mobile" && value.length <= 10) {
      setMobile(e.target.value)
    }
    if (inptext === "dob") {
      setDob(e.target.value)
    }
    if (inptext === "email") {
      setEmail(e.target.value)
    }
    if (inptext === "pass") {
      setPass(e.target.value)
    }
    if (inptext === "copass") {
      setcoPass(e.target.value)
    }
    if (inptext === "referral_code") {
      console.log(e.target.value)
    }

  }


  const body_json = {
    name: namel + ' ' + namer,
    email: email,
    birthday: dob,
    password: pass,
    phone_no: mobile,
    gender: gender,
    country_code: countryMobile,
  }

  const navigate = useNavigate()

  const regSubmit = (event) => {
    event.preventDefault()

    if (capVal != null) {
      setSpinner(true)
      if (pass == copass) {
        instance
          .post('/api/v1/auth/register', body_json)
          .then((response) => {
            // setSpinner(true)
            console.log(response)

        if (response.status !== 201 && response.statusText !== 'Created') {
          setResponseData(response.data)
          // toast.error(response.statusText)
        } else {
          setResponseData(response.data)
          console.log(response.data)
          // toast.success('Registered Successfully')
          localStorage.setItem('reg_detail', JSON.stringify(body_json))

              const acc_jason = {
                email: email,

              }
              const Pacc_jason = {
                country_code: countryMobile,
                phone_no: mobile

              }

              instance
                .post('api/v1/auth/account-verification/generate-otp', acc_jason)
                .then((response) => {
                  console.log(response)
                  toast.success(response.data.msg)
                })
                .catch((error) => {
                  console.log(error)
                  toast.error(error.response.data.msg)
                  setSpinner(false)

                })
              // ------------phone otp generate -------------

              instance
                .post('/api/v1/auth/account-verification/generate-otp', Pacc_jason)
                .then((response) => {
                  console.log(response)
                  toast.success(response.data.msg)
                  // if (response.data.success !== true) {
                  // } else {
                  // toast.success('OTP Sent Successfully')
                  // }


                  setTimeout(() => {
                    navigate('/sendOtp',
                      {
                        state: {
                          name: namel,
                          lastname: namer,
                          email: email,
                          birthday: dob,
                          password: pass,
                          phone_no: mobile,
                          gender: gender,
                          country_code: countryMobile,
                        }
                      }
                    ) // Navigate to other page after 3 seconds
                  }, 3000)
                  // navigate('/')

              // otpGenerated = true; // Set the flag to true after OTP generation
            })
            .catch((error) => {
              console.log(error)
              toast.error(error.response.data.msg)
              setSpinner(false)
            })
        }
      })
      .catch((error) => {
        console.log(error)
        setSpinner(false)
        setErrorData(error.response)
        toast.error(error.response.data.msg)
      })
    }else{
      setSpinner(false)
      toast.error("Password and confirm password does not match")
    }
  }
  else{
    setSpinner(false)
    Capnotverify("Please Verify Captcha")
  }
  }

  const googleAuthProvider = new GoogleAuthProvider();
  const facebookAuthProvider = new FacebookAuthProvider();

  const handleLinkSocialAccount = async (e, provider) => {
    e.preventDefault();
    try {
      let res;
      if (provider === 'google') {
        res = await signInWithPopup(auth, googleAuthProvider);
        // await signOut(auth);
        let bodyData = res.user.providerData[0]
        const response = await instance
          .post('/api/v1/auth/socialAccountAuth', bodyData);

        let token = response.data.token
        console.log(response.data);
        setAccess_token(token)
        localStorage.setItem('user_token', token)
        localStorage.setItem('user_level', response.data.role)
        Cookies.set("user_token", token)
        Cookies.set('user_level', response.data.role)
        // window.location.reload()
        navigate('/myorder')




      }
      else if (provider === 'facebook') {
        console.log("facebook singup")

        res = await signInWithPopup(auth, facebookAuthProvider);

        let bodyData = res.user.providerData[0]
        // for (const key in bodyData) {
        //   if (bodyData.hasOwnProperty(key) && bodyData[key] === null) {
        //     bodyData[key] = ""; // Replace null with an empty string
        //   }
        // }

        // await signOut(auth);
        console.log(bodyData)
        const response = await instance
          .post(
            '/api/v1/auth/socialAccountAuth', bodyData);
        console.log(response)
        let token = response.data.token
        setAccess_token(token)
        localStorage.setItem('user_token', token)
        localStorage.setItem('user_level', response.data.role)
        Cookies.set("user_token", token)
        Cookies.set('user_level', response.data.role)
        navigate('/myorder')

      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error)
      // toast.error("Invalid Credentials! Please try again.")
    }
  };



  return (
    <>
      <ToastContainer />

      {spinner ? (
        <Audio
          height="80"
          width="80"
          radius="9"
          color="green"
          ariaLabel="loading"
          background="transparent"
          wrapperClass="loader"
        />
      )
        :
        (
          <div className="flex login_signup_main_section">
            <ToastContainer />

            <div
              className="basis-1/2 md:block hidden auth_form_side_img_bg"
            // style={{ background: 'background: #f1e6f9' }}
            >
              <img
                src={NavyaImg}
                alt=""
                className="img-fluid"
                style={{ height: '100vh' }}
              />
            </div>
            <div className="mx-auto p-3">
              {/* <div>
          <Link to={'/signIn'} className="font-medium">
            <i className="fa fa-arrow-left font-normal"></i>
            <span className="px- text-lg"> Back</span>
          </Link>
        </div> */}
              <div className="font-bold text-2xl">
                <h1>Sign Up for Navya® Student Portal Today! </h1>
              </div>
              <div className="">
                <p>Please Enter Your Details To Sign Up Into Account</p>
                <p>Enter your mobile number or Email to continue.</p>
              </div>

              <div className="pt-3 w-full">
                <form action="" className="" onSubmit={regSubmit}>
                  <div className="flex flex_dir_col">
                    <div className="border-[#aeaeae] border rounded p-2 mx-1 w-full">
                      <input
                        value={namel}
                        type="text"
                        name="firstname"
                        required
                        onChange={(e) => { inptextset(e, 'namel') }}
                        id="namel"
                        className="w-full border-none focus:border-none focus:outline-none"
                        placeholder="Enter First Name"
                      />
                    </div>
                    <div className="border-[#aeaeae] border rounded p-2 mx-1 margin_top_3 w-full">
                      <input
                        value={namer}
                        type="text"
                        required
                        name="lastname"
                        onChange={(e) => { inptextset(e, 'namer') }}
                        id="namer"
                        className="w-full border-none focus:border-none focus:outline-none"
                        placeholder="Enter Last Name"
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="mt-3 border-[#aeaeae] border rounded p-2 relative flex w-full">
                      <div className="">
                        <i className="fa fa-phone text-[grey]"></i>
                      </div>
                      <div className="w-full">
                        <input
                          value={mobile}
                          type="tel"
                          max={10}
                          required
                          name="mobile"
                          onChange={(e) => inptextset(e, 'mobile')}
                          id="mobile"
                          className="w-full border-none focus:border-none focus:outline-none"
                          placeholder="Enter Phone Number"
                          style={{ paddingLeft: '6.5rem' }}
                        />
                        <select
                          className="form-select mobile_country_code !w-[7rem]"
                          aria-label="Default select example"
                          onChange={handleCountryChange}
                          value={countryMobile}
                        >
                          <option value=""> Country</option>
                          {Array.isArray(responsecountryData) && responsecountryData.length > 0 ? (
                            responsecountryData.map((course) => (
                              <option value={course.dial_code}
                              >{course.name + '(' + course.dial_code + ')'}</option>
                            ))
                          ) : ''
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='flex  '>
                    <div className="mt-3 border-[#aeaeae] border rounded p-2 w-[80%] flex">
                      <div className="">
                        {/* <i class="fa-regular fa-venus-mars"></i> */}
                        <BiMaleFemale size={"1.3em"} color='grey' />
                      </div>
                      <div className="w-full">
                        <select
                          required
                          className='w-[88%] pl-[10px]'
                          name="gender" id="gender" value={gender} onChange={(e) => setGender(e.target.value)}>
                          <option
                            className="w-full border-none focus:border-none focus:outline-none pl-2"
                            value="Male">Male</option>
                          <option
                            className="w-full border-none focus:border-none focus:outline-none pl-2"
                            value="Female">Female</option>
                          <option
                            className="w-full border-none focus:border-none focus:outline-none pl-2"
                            value="Others">Others</option>
                        </select>
                        {/* <input
                  value={gender}
                    type=""
                    required
                    name="email"
                    onChange={(e) => inptextset(e,'email')}
                    id=""
                    className="w-full border-none focus:border-none focus:outline-none pl-2"
                    placeholder="Enter Your Email Here"
                  /> */}
                      </div>
                    </div>

                    <div className="mt-3 border-[#aeaeae] border rounded p-2 mx-1 w-full flex">
                      <span className='w-full'>DOB :  &nbsp;</span>
                      <input
                        value={dob}
                        required
                        type="date" min="1970-01-02" max={today}
                        name="dob"
                        onChange={(e) => inptextset(e, 'dob')}
                        id=""
                        className="w-full border-none focus:border-none focus:outline-none"
                        placeholder=""
                      />
                    </div>
                  </div>


                  <div className="mt-3 border-[#aeaeae] border rounded p-2 relative flex">
                    <div className="">
                      <i className="fa fa-envelope text-[grey]"></i>
                    </div>
                    <div className="w-full">
                      <input
                        value={email}
                        type="email"
                        required
                        name="email"
                        onChange={(e) => inptextset(e, 'email')}
                        id=""
                        className="w-full border-none focus:border-none focus:outline-none pl-2"
                        placeholder="Enter Your Email Here"
                      />
                    </div>
                  </div>


                  <div className="mt-3 border-[#aeaeae] border rounded p-2 relative flex">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      name="pass"
                      required
                      onChange={(e) => inptextset(e, 'pass')}
                      id=""
                      className="w-full border-none focus:border-none focus:outline-none"
                      placeholder="Create Your Password Here"
                    />
                    {/* <div className="absolute right-3">
                  <i className="fa fa-eye-slash text-[grey]"></i>
                </div> */}
                    <InputAdornment position="end" className="show_hide_btn">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  </div>
                  <div className="mt-3 border-[#aeaeae] border rounded p-2 relative">
                    <input
                      type={showcoPassword ? 'text' : 'password'}
                      name="co_pass"
                      required
                      onChange={(e) => inptextset(e, 'copass')}
                      id=""
                      className="w-full border-none focus:border-none focus:outline-none"
                      placeholder="Confirm Your Password Here"
                    />
                    {/* <span className="absolute right-3">
                  <i className="fa fa-eye-slash text-[grey]"></i>
                </span> */}
                    <InputAdornment position="end" className="show_hide_btn">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowcoPassword}
                        onMouseDown={handleMouseDowncoPassword}
                        edge="end"
                      >
                        {showcoPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  </div>
                  <div className="mt-3 border-[#aeaeae] border rounded p-2 relative">
                    <input
                      type={showcoPassword ? 'text' : 'password'}
                      name="referral_code"

                      onChange={(e) => inptextset(e, 'referral_code')}
                      id=""
                      className="w-full border-none focus:border-none focus:outline-none"
                      placeholder="Enter referral code (Optional)"
                    />
                  </div>
                  <div className="py-4 text-end">
                    <ReCaptcha />
                  </div>
                  <div className="mt-0 flex justify-between gap-8">
                    <div
                      className="pb-0 text-center gap-6 flex social_login_btn_width"
                      style={{ justifyContent: 'space-around' }}
                    >
                      <button className="order_details_box_shadow py-3 px-3 bg-[#e6e6e6] inline rounded"
                        onClick={(e) => handleLinkSocialAccount(e, 'google')}
                      >
                        <img
                          src={require(`../../assets/images/google.png`)}
                          className="inline "
                          alt=""
                          width={25}
                        />
                      </button>

                      <button className="order_details_box_shadow py-3 px-3 bg-[#e6e6e6] inline rounded"
                        onClick={(e) => handleLinkSocialAccount(e, 'facebook')}
                      >
                        <img
                          src={require(`../../assets/images/facebook.png`)}
                          className="inline "
                          alt=""
                          width={25}
                        />
                      </button>
                    </div>
                    <button
                      type="submit"
                      required
                      name=""
                      id="login-btn"
                      className="w-[60%] p-9 border-none focus:border-none focus:outline-none text-white btn_hover bg-[#66BE5E] rounded py-2"
                      value="Let's Sign Up"
                    >
                      Let's Sign Up

                    </button>
                  </div>
                </form>
              </div>
              <div className="pt-2 text-center">
                <p className="">
                  Already Have An Account?{' '}
                  <span className="text-[#38a32e] font-semibold">
                    <Link to={'/signIn'}>Login</Link>
                    {/* <button onClick={notify}>Notify!</button> */}
                  </span>
                </p>
              </div>

{/* 
              <div className="pb-0 mt-0 text-center">
                <p className="  bg-[white] inline rounded">Link your Social Account</p>
              </div> */}


            </div>
          </div>
        )}

    </>
  )
}

export default SignUp
